.largeItem {
  display: flex;
  flex-direction: column;
  width: 11em;
  height: auto;
  position: relative;
  justify-self: flex-start;
  flex-grow: 0;
  flex-shrink: 1;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.1s;
  border-radius: 1em;
  padding: 0.68em;
}
.largeItem > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.largeItem > :global(.__wab_flex-container) > *,
.largeItem > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.largeItem > :global(.__wab_flex-container) > picture > img,
.largeItem
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6px;
}
.largeItemitemType_map {
  padding: 0.68em;
}
.largeItemitemType_map > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.largeItemitemType_map > :global(.__wab_flex-container) > *,
.largeItemitemType_map
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.largeItemitemType_map > :global(.__wab_flex-container) > picture > img,
.largeItemitemType_map
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 8px;
}
.largeItemisSelected {
  background: #fdfdfc0a;
  box-shadow: none;
  border-radius: 1em;
}
.largeItemisEditing {
  width: 11em;
  justify-self: flex-start;
}
.largeItem:hover {
  transform: none;
  background: #ffffff0a;
}
.largeItemitemType_folder:hover {
  box-shadow: 0px 4px 16px 0px #0000001a;
}
.largeItemitemType_map:hover {
  background: #ffffff0a;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 1em;
}
.graphicContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: center;
  transition-property: opacity;
  transition-duration: 0.8s;
  min-width: 0;
  -webkit-transition-property: opacity;
  -webkit-transition-duration: 0.8s;
  padding: 0px;
}
.graphicContaineritemType_map {
  overflow: hidden;
}
.graphicContaineropacityLevelVariant__9 {
  opacity: 0.85;
}
.graphicContaineropacityLevelVariant__8 {
  opacity: 0.7;
}
.graphicContaineropacityLevelVariant__7 {
  opacity: 0.55;
}
.graphicContaineropacityLevelVariant__6 {
  opacity: 0.5;
}
.graphicContaineropacityLevelVariant__5 {
  opacity: 0.45;
}
.mapFolderIcon:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.mapFolderIconitemType_folder:global(.__wab_instance) {
  flex-shrink: 0;
}
.mapFolderIconitemType_map:global(.__wab_instance) {
  flex-shrink: 0;
}
.mapFolderIconisSelected:global(.__wab_instance) {
  flex-shrink: 0;
}
.mapFolderIconcolor_white:global(.__wab_instance) {
  flex-shrink: 0;
}
.mapFolderIconopacityLevelVariant__9:global(.__wab_instance) {
  flex-shrink: 0;
}
.mapFolderIconopacityLevelVariant__7:global(.__wab_instance) {
  flex-shrink: 0;
}
.mapFolderIconopacityLevelVariant__6:global(.__wab_instance) {
  flex-shrink: 0;
}
.mapFolderIconopacityLevelVariant__5:global(.__wab_instance) {
  flex-shrink: 0;
}
.largeItem:hover .mapFolderIcon:global(.__wab_instance) {
  flex-shrink: 0;
}
.mapPreviewImageContainer {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.159);
  background: #ffffff17;
  width: 100%;
  height: 100px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-width: 0;
  display: none;
  border-radius: 12px;
}
.mapPreviewImageContaineritemType_map {
  display: flex;
}
.bottom {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.bottomitemType_map {
  padding-left: 0.5em;
}
.titleAndIconContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  flex-shrink: 0;
  flex-grow: 1;
  max-width: 10.3em;
  min-width: 0;
  padding: 0px;
}
.titleAndIconContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.titleAndIconContainer > :global(.__wab_flex-container) > *,
.titleAndIconContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.titleAndIconContainer > :global(.__wab_flex-container) > picture > img,
.titleAndIconContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.titleAndIconContaineritemType_folder {
  padding-left: 1em;
}
.iconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  max-width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  padding: 0px;
}
.svg__pnVdf {
  object-fit: cover;
  max-width: 100%;
  align-self: center;
  height: 1em;
}
.titleContainer {
  position: relative;
  width: 10.3em;
  height: auto;
  display: flex;
  transform: none;
  max-height: 48px;
  flex-direction: row;
  max-width: 10.3em;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 5em;
  margin-right: 0px;
  margin-left: calc(8px + 8px) !important;
}
.titleContainer > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.titleContainer > :global(.__wab_flex-container) > *,
.titleContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.titleContainer > :global(.__wab_flex-container) > picture > img,
.titleContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.titleContaineritemType_folder {
  margin-left: calc(8px + 8px) !important;
}
.titleContaineritemType_map {
  margin-left: calc(8px + 8px) !important;
}
.titleContainerisSelected {
  margin-left: calc(8px + 8px) !important;
}
.titleContainercolor_white {
  margin-left: calc(8px + 8px) !important;
}
.titleContaineropacityLevelVariant__0 {
  margin-left: calc(8px + 8px) !important;
}
.titleContainerisEditing {
  width: 100%;
  max-width: 100%;
  margin-left: calc(8px + 8px) !important;
  min-width: 0;
}
.titleContainerisEditing > :global(.__wab_flex-container) {
  min-width: 0;
}
.largeItem:hover .titleContainer {
  margin-left: calc(8px + 8px) !important;
}
.slotTargetTitle {
  color: var(--token-0_5ZnF2XNivQ);
  text-align: left;
  white-space: pre-wrap;
}
.textInputFromMapMap__zVMcK:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.svg__yWxwQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__a7P2J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
