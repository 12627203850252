.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: #131313;
  min-width: 0;
}
.leftSide {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  max-width: 100%;
  margin-bottom: 0px;
  background: var(--token-R0iI9Z9H2K5U);
  box-shadow: 2px 4px 12px 0px #00000059;
  z-index: 1;
  min-height: 0;
  padding: 8px 34px;
  border-right: 0.1px solid #13a73447;
}
.heroSection {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 8px;
}
.heroSection > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.heroSection > :global(.__wab_flex-container) > *,
.heroSection > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.heroSection > :global(.__wab_flex-container) > picture > img,
.heroSection
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .heroSection {
    display: flex;
    flex-direction: row;
    width: 1000px;
    flex-shrink: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .heroSection > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - 100px);
    width: calc(100% + 100px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .heroSection > :global(.__wab_flex-container) > *,
  .heroSection > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .heroSection > :global(.__wab_flex-container) > picture > img,
  .heroSection
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 100px;
    margin-top: 0px;
  }
}
.textContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.textContainer > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 34px);
  height: calc(100% + 34px);
}
.textContainer > :global(.__wab_flex-container) > *,
.textContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textContainer > :global(.__wab_flex-container) > picture > img,
.textContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 34px;
}
.textLogo {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 200px;
  height: 100%;
  max-width: 100%;
  min-height: 0;
  padding: 8px;
}
.img__o3Myn {
  object-fit: contain;
  max-width: 100%;
}
.img__o3Myn > picture > img {
  object-fit: contain;
}
.textHeadline {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 160px;
  max-width: 100%;
  flex-shrink: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .textHeadline {
    width: 400px;
    height: 200px;
    flex-shrink: 0;
  }
}
.freeBox {
  display: block;
  position: relative;
  width: 49px;
  height: 286px;
  max-width: 100%;
  flex-shrink: 0;
  padding: 8px;
}
.img__iDs0N {
  object-fit: cover;
  max-width: 100%;
  width: auto;
}
.img__iDs0N > picture > img {
  object-fit: cover;
}
.heroVideo {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 500px;
  height: auto;
  display: none;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .heroVideo {
    width: 500px;
    padding-top: 64px;
    flex-shrink: 0;
  }
}
.img__hprIq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 500px;
  flex-shrink: 0;
}
.img__hprIq > picture > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__hprIq {
    width: 460px;
    flex-shrink: 0;
  }
}
.rightSide {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: url("./images/grainBg16.png") top 50% left 50% / 40px 40px repeat,
    #131313;
  z-index: 0;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.img__q6A3 {
  position: relative;
  object-fit: scale-down;
  max-width: 100%;
  width: 200px;
}
.img__q6A3 > picture > img {
  object-fit: scale-down;
}
