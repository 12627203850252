.stack-item-placeholder {
    opacity: 0;
    /* transform: translateY(-10px); */
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    animation: fadeInMove 0.3s ease-in-out forwards;
  }
  
  @keyframes fadeInMove {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }