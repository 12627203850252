.mapFolderIcon {
  display: block;
  width: 115px;
  height: 131px;
  max-width: 100%;
  position: relative;
  padding: 0px;
}
.sheets {
  display: block;
  width: 115px;
  height: 131px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.img__u9AqC {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  width: 71px;
  height: 103px;
  display: block !important;
  left: 44px;
  top: 14px;
  border-radius: 10.824px;
}
.img__u9AqC > picture > img {
  object-fit: cover;
}
.img__tGl95 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  backdrop-filter: blur(1.353px);
  width: 88px;
  height: 118px;
  display: block !important;
  left: 19px;
  top: 6px;
  -webkit-backdrop-filter: blur(1.353px);
  border-radius: 10.824px;
}
.img__tGl95 > picture > img {
  object-fit: cover;
}
.img__zU6V1 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  backdrop-filter: blur(1.353px);
  width: 94px;
  height: 131px;
  display: block !important;
  left: 0px;
  top: 0px;
  transform: skewX(0deg) skewY(0deg);
  transition-property: transform;
  transition-duration: 0.12s;
  -webkit-backdrop-filter: blur(1.353px);
  -webkit-transition-property: transform;
  -webkit-transition-duration: 0.12s;
  border-radius: 10.824px;
}
.img__zU6V1 > picture > img {
  object-fit: cover;
}
.iconContainer {
  display: block;
  position: absolute;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: 0px;
  top: 40px;
  min-width: 0;
  padding: 8px;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  left: 14px;
  top: -18px;
  color: var(--token-0_5ZnF2XNivQ);
  transform: skewX(0deg) skewY(-2deg);
  height: 1em;
}
