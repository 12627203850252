.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  padding-left: 0.3em;
  padding-right: 0.3em;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 30px;
}
.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
  margin-bottom: 4px;
  max-height: 400px;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.mainNav {
  display: flex;
  width: auto;
  height: 6.8em;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .mainNav {
    width: 100%;
    align-items: center;
    min-width: 0;
  }
}
.overviewButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.svg__tSfw6 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1.2em;
  height: 1.2em;
  display: block;
}
.foldersButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .foldersButton:global(.__wab_instance) {
    left: auto;
    top: auto;
  }
}
.svg__mwc7T {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1.2em;
  height: 1.2em;
  display: block;
}
.text4 {
  margin-right: 0px;
}
.notificationsButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .notificationsButton:global(.__wab_instance) {
    left: auto;
    top: auto;
  }
}
.svg__aHgeA {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1.2em;
  height: 1.2em;
  display: block;
}
.settingsButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .settingsButton:global(.__wab_instance) {
    left: auto;
    top: auto;
  }
}
.svg__v0E5Y {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1.2em;
  height: 1.2em;
  display: block;
}
.bottom {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 60%;
  min-width: 0;
  padding: 8px 8px 10%;
}
.bottom > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-content: stretch;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.bottom > :global(.__wab_flex-container) > *,
.bottom > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.bottom > :global(.__wab_flex-container) > picture > img,
.bottom
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.quickAccessContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  min-width: 0;
  padding: 8px;
}
.quickAccess:global(.__wab_instance) {
  max-width: 100%;
  min-height: 80%;
}
.dropdownItem__pMFd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___3VA2C {
  height: auto;
}
.svg__uPmWh {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.dropdownItemGroup:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dropdownItem__jiXa5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__pCiks {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.dropdownItem__likAp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___4LWkg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.dropdownItem__mk0A:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__q4T7E {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.dropdownItem__bnIei:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin: 0em;
}
.text__lQiYx {
  padding: 0em;
  margin: 0em;
}
.svg__mk69 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.mapFolderSearchFancyButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.logOutButton {
  width: auto;
  height: 1.014em;
  display: flex;
  flex-direction: row;
  transition-property: all;
  transition-duration: 1s;
  cursor: pointer;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.logOutButton > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 2.4px);
  width: calc(100% + 2.4px);
}
.logOutButton > :global(.__wab_flex-container) > *,
.logOutButton > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.logOutButton > :global(.__wab_flex-container) > picture > img,
.logOutButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2.4px;
}
.svg__ftsiD {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  width: 0.9em;
  height: 0.9em;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.labelUnderline {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .labelUnderline {
    display: none;
  }
}
.label {
  display: block;
  font-size: 0.675em;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.05em;
  color: #9ba2ae;
  position: relative;
}
.underline {
  background: rgba(123, 57, 236, 1);
  height: 0.075em;
  display: block;
  position: relative;
  align-self: stretch;
  flex-shrink: 0;
  border-width: 0em;
}
.svg___3MlOu {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #8b5cf6;
  width: 0.9em;
  height: 0.9em;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
