.persistenceDropdown {
  background: #272727;
  box-shadow: inset 0px 0px 0px 0.4px rgba(34, 34, 34, 1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: auto;
  height: auto;
  display: inline-flex;
  flex-direction: row;
  position: relative;
  border-radius: 8px;
  padding: 4px 16px;
}
.persistenceDropdown > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.persistenceDropdown > :global(.__wab_flex-container) > *,
.persistenceDropdown
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.persistenceDropdown > :global(.__wab_flex-container) > picture > img,
.persistenceDropdown
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__wpycs {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.svg___4Otzr {
  object-fit: cover;
  max-width: 100%;
  color: #13a734;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.svgstate_allFutureSteps___4OtzrNai2 {
  display: none;
}
.svgstate_allSteps___4OtzrXcOgN {
  display: none;
}
.svg__tcOUj {
  object-fit: cover;
  max-width: 100%;
  color: #5978e8;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.svgstate_allFutureSteps__tcOUjNai2 {
  display: block;
}
.svg__xp7So {
  object-fit: cover;
  max-width: 100%;
  color: #f87171;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.svgstate_allSteps__xp7SoXcOgN {
  display: block;
}
.svg__mJgwJ {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
