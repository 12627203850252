.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
  min-height: 0;
  padding: 0.5em;
}
.spacer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.main {
  background: #1f1f1f;
  box-shadow: inset -0.7px -1px 0px 0px rgba(50, 50, 50, 0.25),
    inset 0.699px 1px 1px 0px rgba(98, 98, 98, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  display: flex;
  min-height: none;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  border-radius: 1.5em;
  padding: 1.2em 1.2em 1.2em 3em;
}
.main > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.main > :global(.__wab_flex-container) > *,
.main > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.main > :global(.__wab_flex-container) > picture > img,
.main > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 12px;
}
.text__hqnMx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  text-align: left;
  font-size: 1.2em;
  margin-top: calc(12px + 12px) !important;
  min-width: 0;
}
.foldersAndMaps {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
  overflow: auto;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.mapContainer {
  display: flex;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  min-width: 0;
  padding: 0em;
}
.slotTargetRecentMaps {
  font-size: 1em;
}
.slotTargetRecentMaps > *,
.slotTargetRecentMaps > :global(.__wab_slot) > *,
.slotTargetRecentMaps > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetRecentMaps
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetRecentMaps > picture > img,
.slotTargetRecentMaps > :global(.__wab_slot) > picture > img,
.slotTargetRecentMaps
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetRecentMaps
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.folderContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  min-width: 0;
  padding: 0em;
}
.slotTargetRecentFolders {
  font-size: 1em;
}
.largeItem__hM3DZ:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.svg__aJpt0 {
  object-fit: cover;
  max-width: 100%;
  align-self: center;
  height: 1em;
}
.textInputFromMapMap__qtZP:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.svg__tkijy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ieSgs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tEeep {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  text-align: left;
  font-size: 1.5em;
  min-width: 0;
  display: none;
}
