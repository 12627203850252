.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  overflow: visible;
  min-width: 0;
}
.floatingBar:global(.__wab_instance) {
  max-width: 100%;
}
.homeButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.svg__vfSpP {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
}
.insertModeButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.svg__sT3O3 {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
}
.shiftButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.svg__rton7 {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
}
.copyButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.svg__mBFE {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
}
.areaCreationButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.svg__edk4H {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
}
.screenshotCreationButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.svg__jpsaS {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
}
.searchButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.svg___3S5FB {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
}
.moreButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.moreButtonset_initial:global(.__wab_instance) {
  margin-right: -4px;
}
.svg___5CrAs {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
}
.outlineModeButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.svg__bSg1 {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
}
.panningButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.svg__mTqcU {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
}
.infoModeButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.svg__bOcqk {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
}
.commentButton:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.svg__y034A {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
  color: #fff;
}
