.root {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  justify-content: center;
  align-items: flex-start;
  justify-self: flex-start;
  cursor: pointer;
  max-width: 100%;
}
.rootroundedCorners_none {
  width: auto;
  justify-self: flex-start;
  display: inline-flex;
}
.rootisInSidebar {
  width: 100%;
  min-width: 0;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  border-radius: 0.5em;
  padding: 0.2em 0.5em;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.freeBoxindentation__1 {
  padding-left: 32px;
}
.freeBoxindentation__2 {
  padding-left: 2.875em;
}
.freeBoxsize_small > :global(.__wab_flex-container) {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxsize_small > :global(.__wab_flex-container) > *,
.freeBoxsize_small > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBoxsize_small > :global(.__wab_flex-container) > picture > img,
.freeBoxsize_small
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.freeBoxisExpanding {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  min-width: 0em;
  min-height: 0em;
}
.freeBoxlevel__1 {
  background: #ffffff05;
}
.freeBoxlevel__2 {
  background: #ffffff1f;
}
.freeBoxlevel__3 {
  background: #ffffff33;
}
.freeBoxroundedCorners_none {
  border-radius: 0em;
}
.freeBoxisInSidebar {
  width: 100%;
  min-width: 0;
  padding: 0.5em;
}
.freeBoxisInSidebar > :global(.__wab_flex-container) {
  justify-content: space-between;
  min-width: 0;
}
.freeBoxisHighlighted {
  background: linear-gradient(0deg, #2f2f31 0%, #3b3b3b 100%) 0% 0% / 100% 100%
    repeat;
  box-shadow: 0px 2px 4px 0px #0000001f;
}
.root:hover .freeBox {
  background: #ffffff0d;
}
.root:active .freeBox {
  background: #ffffff05;
}
.indentationTreeContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 1.5em;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
  padding: 0px;
}
.iconAndName {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  cursor: pointer;
  min-width: 0;
}
.iconAndName > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.iconAndName > :global(.__wab_flex-container) > *,
.iconAndName > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.iconAndName > :global(.__wab_flex-container) > picture > img,
.iconAndName
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .iconAndName {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
  }
}
.leftIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1.75em;
  height: 1.75em;
  max-width: 100%;
  flex-shrink: 0;
  padding: 0px;
}
.leftIconContainersize_small {
  width: 1.313em;
  height: 1.313em;
  flex-shrink: 0;
}
.svg__fnZz0 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.itemNameContainer {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  min-width: 0;
}
.slotTargetName {
  white-space: pre;
  color: var(--token-0_5ZnF2XNivQ);
  font-size: 1em;
}
.slotTargetName > :global(.__wab_text),
.slotTargetName > :global(.__wab_expr_html_text),
.slotTargetName > :global(.__wab_slot-string-wrapper),
.slotTargetName > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetName > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetName > :global(.__wab_slot) > :global(.__wab_slot-string-wrapper),
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetName > *,
.slotTargetName > :global(.__wab_slot) > *,
.slotTargetName > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetName > picture > img,
.slotTargetName > :global(.__wab_slot) > picture > img,
.slotTargetName > :global(.__wab_slot) > :global(.__wab_slot) > picture > img,
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetNamesize_small {
  font-size: 0.75em;
}
.rightIconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  cursor: pointer;
  padding: 0px;
}
.rightIconContainerisExpansionDisabled {
  opacity: 0.25;
}
.firstRightIconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0.5em;
}
.firstRightIcon {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.secondRightIconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  max-width: 100%;
  flex-shrink: 0;
  padding: 0px;
}
.secondRightIconContainersize_small {
  width: 1.125em;
  height: 1.125em;
  flex-shrink: 0;
}
.secondRightIconContaineralternativeArrowIcon {
  width: 0.6em;
  height: 0.6em;
  flex-shrink: 0;
}
.secondRightIcon {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #fff7ed;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.secondRightIconisLeftIconHidden {
  display: none;
}
.secondRightIconexpansionIcon_hidden {
  display: none;
}
.secondRightIconexpansionIcon_left {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(-180deg);
}
.secondRightIconexpansionIcon_up {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(-90deg);
}
.secondRightIconexpansionIcon_down {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(90deg);
}
.secondRightIconalternativeArrowIcon {
  margin-bottom: 0px;
  color: #fff7ed99;
  display: block;
}
.secondRightIconexpansionIcon_hidden_alternativeArrowIcon {
  display: none;
}
