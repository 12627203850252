
.clearfix {
  content: '';
  display: table;
  clear: both;
}

.full-width {
  max-width: 100%;
  width: 100%;

  > * {
    width: 100%;
    max-width: 100%;
  }
}

.restrict-width {
  max-width: $max-width;
  margin: 0 auto;
}

.no-padding {
  padding: 0 !important;
}

.align-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}

.align-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.align-xy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.align-center {
  text-align: center;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.multiline-ellipsis {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  white-space: normal !important;
  text-overflow: ellipsis !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn div {
  color: #13a734;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #13a734;
}

.epr-main {
  background: transparent !important;
  border: 0px !important;
  width: 100% !important;
}

.modal-input {
  background-color: #09090b !important;
  border: 1px #616167 solid !important;
  color: #6a6a70 !important;
}

.modal-input::placeholder {
  color: #6a6a70;
}

.modal-table {
  background-color: #09090b !important;
  border: 1px #616167 solid !important;
  color: #616167 !important;
  border-radius: 12px !important;

  thead,
  tbody {
    tr {
      th {
        color: #6a6a70;
      }

      td {
        color: #ececec;
      }
    }
  }
}

.custom {
  background-color: #171717;
  color: #ececec;
}

.shortcut-keys {
  background-color: #27272a;
}



.outline-btn {
  border: 1px solid #161617;
}

.map-suggestions {
  background: url(http://localhost:3000/static/media/innerFrame.407f6d9….png) 0% 0% / 80px 108px repeat, rgba(255, 255, 255, 0.119);
  box-shadow: inset 0px 0px 0px 0.6px rgba(255, 255, 255, 0.159);
  backdrop-filter: blur(3px);
  width: 100%;
  height: 27px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex-grow: 1;
  -webkit-backdrop-filter: blur(3px);
  border-radius: 4px;
  padding: 7.696px;
}

.map-suggestions:hover {
  background: url(http://localhost:3000/static/media/innerFrame.407f6d9….png) 0% 0% / 80px 108px repeat, #7d2c2cad;
  box-shadow: inset 0px 0px 0px 0px rgba(255, 255, 255, 0.159), 0px 0px 2px 0px var(--token-t5atQMpMvMnt);
  overflow: visible;
  border: 1.2px solid #ff86868f;
  color: #FFFFFFCC;
  cursor: pointer;
}

.selected-map {
  background: url(http://localhost:3000/static/media/innerFrame.407f6d9….png) 0% 0% / 80px 108px repeat, #7d2c2cad;
  box-shadow: inset 0px 0px 0px 0px rgba(255, 255, 255, 0.159), 0px 0px 2px 0px var(--token-t5atQMpMvMnt);
  overflow: visible;
  border: 1.2px solid #ff86868f;
  color: #FFFFFFCC;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: #ffffff !important;
  transition: background-color 5000s ease-in-out 0s !important;
  box-shadow: inset 0 0 20px 20px #23232329 !important;
  background-color: transparent !important;
}

input,
input:focus,
input:active {
  background-color: transparent !important;
  border: transparent !important;
  outline: transparent !important;
}

// Home icon on the breadcrumb
.ant-breadcrumb ol :first-child {
  display: flex;
  align-items: center;
}

.ant-breadcrumb ol :first-child .ant-breadcrumb-link :first-child {
  display: flex;
  align-items: center;
}

@keyframes emptyMapSlideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
