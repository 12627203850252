.closeCircleContainer {
  display: flex;
  width: 8px;
  height: 8px;
  max-width: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
}
.outer {
  display: flex;
  width: 8px;
  height: 8px;
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  background: linear-gradient(35deg, #851a1a 37%, #a65252 100%) 0% 0% / 100%
    100% repeat;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 12px 0px #dd4040a6, 0px 0px 2.6px 0px #dd404059;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
}
.outercolor_yellow {
  background: linear-gradient(35deg, #5f3d0a 37%, #b4923c 100%) 0% 0% / 100%
    100% repeat;
  box-shadow: 0px 0px 12px 0px #dd954080, 0px 0px 2.6px 0px #dd9e4040;
}
.closeCircleContainercolor_red:hover .outercolor_red {
  background: linear-gradient(35deg, #d20909 37%, #bd3434 100%) 0% 0% / 100%
    100% repeat;
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
}
.closeCircleContainercolor_yellow:hover .outercolor_yellow {
  background: linear-gradient(35deg, #cb800e 37%, #d5a939 100%) 0% 0% / 100%
    100% repeat;
}
.inner {
  display: block;
  position: relative;
  width: 5.6px;
  height: 5.6px;
  max-width: 100%;
  left: auto;
  top: auto;
  background: linear-gradient(35deg, #ef3939 0%, #ed7676 100%) 0% 0% / 100% 100%
    repeat;
  align-self: center;
  box-shadow: inset 0.24px -0.71px 1px 0px #00000040;
  flex-shrink: 0;
  border-radius: 100px;
  padding: 0px;
}
.innercolor_yellow {
  background: linear-gradient(35deg, #d88d1d 40%, #e7bd51 76%) 0% 0% / 100% 100%
    repeat;
}
.closeCircleContainercolor_red:hover .innercolor_red {
  background: linear-gradient(35deg, #f75e5e 0%, #d47373 100%) 0% 0% / 100% 100%
    repeat;
  box-shadow: inset 0px 0px 1px 0px #00000033;
}
.closeCircleContainercolor_yellow:hover .innercolor_yellow {
  background: linear-gradient(35deg, #e79f34 40%, #e0c377 76%) 0% 0% / 100% 100%
    repeat;
  box-shadow: inset 0px 0px 1px 0px #0000001f;
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
}
