.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.floatingBar:global(.__wab_instance) {
  max-width: 100%;
  width: 480px;
}
.freeBox__tlcYu {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  background: none;
  transition-property: all;
  transition-duration: 0.1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.1s;
  border-radius: 4px;
  padding: 2px;
}
.root .freeBox__tlcYu:hover {
  background: var(--token-R0iI9Z9H2K5U);
  left: auto;
  top: auto;
}
.svg___8Plcm {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  left: auto;
  top: auto;
  flex-shrink: 0;
}
.center {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  left: auto;
  top: auto;
}
.center > :global(.__wab_flex-container) {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.center > :global(.__wab_flex-container) > *,
.center > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.center > :global(.__wab_flex-container) > picture > img,
.center
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
  margin-top: 12px;
}
.stepDisplay {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.currentStep {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.svg__wIbO1 {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.maxStepNumber {
  display: block;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.persistenceDropdown:global(.__wab_instance) {
  position: relative;
}
.freeBox__hrYe6 {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  background: none;
  transition-property: all;
  transition-duration: 0.1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.1s;
  border-radius: 4px;
  padding: 2px;
}
.root .freeBox__hrYe6:hover {
  background: var(--token-R0iI9Z9H2K5U);
  left: auto;
  top: auto;
}
.svg__gAYfQ {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 24px;
  height: 24px;
  display: block;
  flex-shrink: 0;
}
