.outsideSpaceCustom {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: inherit;
  justify-self: flex-start;
  margin-left: 0px;
  padding: 0.12em;
}
.selectionShadowCustom {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: inherit;
  width: 105%;
  border-radius: 0.4em;
  padding: 0em;
  background-color: #1e1d1d;
}
.selectionShadowisSelectedCustom {
  box-shadow: 0px 0px 0px 2.5px #f54646;
  /* border-radius: 0.4em; */
}

.selectionShadowisSquared {
  border-radius: 0em;
}
.selectionShadowisEditing {
  padding: 0.08em;
}

.mainBorderCustom {
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow: visible !important;
  border-radius: 0.4em;
  padding: 0px 1px;
  outline: 2px solid transparent;
}
.mainBorderisSquared {
  border-radius: 0em;
}
.mainBorderhasShadow2 {
  box-shadow: 8px 0px 16px 2px #2c282830;
}
.mainBorderisEditing {
  border-radius: 0.3em;
  border: 1px solid var(--token-_jBW4cEMf);
}
.mainBorderborderWeight_0 {
  outline-width: 0px;
  outline-style: solid;
  margin: 2px 0;
  outline-color: none;
}

.mainBorderborderWeight_1 {
  outline-width: 2px;
  outline-style: solid;
  margin: 2px 0;
}
.mainBorderborderWeight_2 {
  outline-width: 3px;
  outline-style: solid;
  margin: 3px 0;
}
.mainBorderborderWeight_3 {
  outline-width: 4px;
  outline-style: solid;
  margin: 4px 0;
}
.mainBorderborderWeight_4 {
  outline-width: 6px;
  outline-style: solid;
  margin: 6px 0;
}
.mainBorderborderWeight_5 {
  outline-width: 8px;
  outline-style: solid;
  margin: 8px 0;
}
/* .mainBorderisEditing_isSelected {
  border-radius: 0.4em;
  border-width: 2px;
  border-color: var(--token-_jBW4cEMf);
} */
.hoverBorderWrapperCustom {
  max-width: 100%;
  width: 100%; /* */
  height: inherit !important; /* */
  position: relative;
}
.hoverDetectionSpace {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: auto;
  top: auto;
  padding: 8px;
}
.selectionShadowisSelectedCustom:has(.mainBorderborderWeight_0) {
  padding: 0 0.1em;
  border-radius: 0.5em;
}
.selectionShadowisSelectedCustom:has(.mainBorderborderWeight_1) {
  padding: 0 2px;
  border-radius: 0.5em;
}
.selectionShadowisSelectedCustom:has(.mainBorderborderWeight_2) {
  padding: 0 3px;
  border-radius: 0.6em;
}
.selectionShadowisSelectedCustom:has(.mainBorderborderWeight_3) {
  padding: 0px 4px;
  border-radius: 10.5px;
}

.selectionShadowisSelectedCustom:has(.mainBorderborderWeight_4) {
  padding: 0 6px;
  border-radius: 0.8em;
}
.selectionShadowisSelectedCustom:has(.mainBorderborderWeight_5) {
  padding: 0 8px;
  border-radius: 0.9em;
}
.outsideSpaceCustom:has(.selectionShadowisSelectedCustom):has(
    .mainBorderborderWeight_5
  ) {
  transform: translateX(-8px);
  width: calc(100% + 16px);
}
.outsideSpaceCustom:has(.selectionShadowisSelectedCustom):has(
    .mainBorderborderWeight_4
  ) {
  transform: translateX(-6px);
  width: calc(100% + 12px);
}
.outsideSpaceCustom:has(.selectionShadowisSelectedCustom):has(
    .mainBorderborderWeight_3
  ) {
  transform: translateX(-4px);
  width: calc(100% + 8px);
}
.outsideSpaceCustom:has(.selectionShadowisSelectedCustom):has(
    .mainBorderborderWeight_2
  ) {
  transform: translateX(-2px);
  width: calc(100% + 5px);
}
.outsideSpaceCustom:has(.selectionShadowisSelectedCustom):has(
    .mainBorderborderWeight_1
  ) {
  transform: translateX(-2px);
  width: calc(100% + 4px);
}
.outsideSpaceCustom:has(.selectionShadowisSelectedCustom):has(
    .mainBorderborderWeight_0
  ) {
  transform: translateX(-0.1em);
  width: calc(100% + 1px);
}
.levels-con {
  background: linear-gradient(-45deg, #242022, #1f242e);
  border: 1px solid rgb(119, 116, 116);
}

.thickness-level {
  margin-bottom: -8px;
  padding: 0.25rem 0.75rem;
  transition: all 0.2s;
}

.thickness-level:hover {
  border-radius: 0.375rem;
  background-color: rgb(239 68 68);
}

.check {
  font-size: 10px;
  margin-left: -13px;
}

.connecting {
  background: rgba(255, 255, 255, 0.192);
  backdrop-filter: blur(10px);

  border-radius: 0, 4em;
}
