.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  max-height: 100%;
  min-width: 0;
  padding: 0.5em;
}
.rootnoMapsAndFolders {
  height: 100%;
  min-height: 0;
}
.top {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0.5em;
}
.title {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 1em;
  min-width: 0;
}
.metadata {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0.5em;
}
.stamps {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0.5em;
}
.attributes {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0.5em;
}
.relatedMaps {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0.5em;
}
.folderBreadcrumbs:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-size: 1em;
}
.firstBreadcrumbItem:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  font-size: 1em;
}
.freeBox__wc3IC {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.svg__xb7Ix {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 16px;
  height: 16px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.secondBreadcrumbItem:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  font-size: 1em;
}
.text__fwaNn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 1em;
  min-width: 0;
}
.breadcrumbItem:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  font-size: 1em;
}
.text__eC0C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 1em;
  min-width: 0;
}
.freeBox__f2XO {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.svg__ronzz {
  object-fit: cover;
  max-width: 100%;
  color: #13a734;
  height: 1em;
}
.main {
  background: #1f1f1f;
  box-shadow: inset -0.7px -1px 0px 0px rgba(50, 50, 50, 0.25),
    inset 0.699px 1px 1px 0px rgba(98, 98, 98, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 1;
  height: 100%;
  overflow: hidden;
  max-width: 100%;
  min-width: 0;
  border-radius: 1.5em;
}
.mainnoMapsAndFolders {
  align-items: center;
  justify-content: flex-start;
  max-height: 100%;
}
.dashboardContentMenu {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  filter: none;
  transform: none;
  min-width: 0;
  padding: 0.5em 1em 0.5em 0.5em;
}
.dashboardContentMenu > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.dashboardContentMenu > :global(.__wab_flex-container) > *,
.dashboardContentMenu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.dashboardContentMenu > :global(.__wab_flex-container) > picture > img,
.dashboardContentMenu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .dashboardContentMenu {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .dashboardContentMenu > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .dashboardContentMenu > :global(.__wab_flex-container) > *,
  .dashboardContentMenu
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .dashboardContentMenu > :global(.__wab_flex-container) > picture > img,
  .dashboardContentMenu
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.dashboardContentMenunoMapsAndFolders {
  padding: 0.5em 1em 0.5em 0.5em;
}
.mapCreation:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.img__cgrWo {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  border-radius: 40px;
}
.img__cgrWo > picture > img {
  object-fit: cover;
}
.textInputFromMapMap__eoeMb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___9NwXt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aq6BR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.folderCreation:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.img___38JNn {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  border-radius: 40px;
}
.img___38JNn > picture > img {
  object-fit: cover;
}
.textInputFromMapMap__nyzNa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__zsdW3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fW2A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__d7OU8 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 36px;
  max-width: 100%;
  align-self: flex-end;
  min-width: 4em;
  padding: 0px;
}
.freeBox__sRmVs {
  width: 65px;
  height: 36px;
  display: block;
  flex-shrink: 0;
}
.freeBox___3LEct {
  box-shadow: 0px 2.942px 2.942px 0px rgba(0, 0, 0, 0.1);
  transform: translate(0px, 0.4710000000000001px);
  width: 100%;
  height: 33px;
  display: block;
  position: absolute;
  top: 1px;
  left: 0%;
  padding-left: 0px;
  border-radius: 17.653px;
}
.freeBox__cokLg {
  background: linear-gradient(
      217deg,
      rgba(52, 48, 49, 1) 68%,
      rgba(42, 37, 38, 1) 142%
    )
    0% 0% / 100% 100% no-repeat;
  box-shadow: inset 0px 0px 0px 0.147px rgba(0, 0, 0, 0.1);
  transform: translate(-0.11599999999999966px, -0.11599999999999966px);
  width: 81.81%;
  height: 24px;
  display: block;
  position: absolute;
  top: 6px;
  left: 9.26%;
  border-radius: 11.769px;
}
.img__mgbeL {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  box-shadow: none;
  transform: translate(0.3550000000000004px, 0.3550000000000004px);
  width: 21px;
  height: 21px;
  display: block !important;
  top: 7px;
  left: 7px;
  filter: drop-shadow(0px 0px 6px #12a633c2);
}
.img__mgbeL > picture > img {
  object-fit: cover;
}
.freeBox__uUwdx {
  transform: translate(0.3780000000000001px, -0.34699999999999953px);
  width: 15px;
  height: 14px;
  display: block;
  position: absolute;
  top: 11px;
  left: 35px;
  overflow: hidden;
}
.svg__fnvkH {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff7ed;
  transform: translate(0.3780000000000001px, 0.15300000000000047px);
  width: 16px;
  height: 17px;
  display: block;
  top: 9px;
  left: 34px;
  overflow: hidden;
}
.freeBox___9SnaD {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBoxnoMapsAndFolders___9SnaDrAguv {
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: auto;
  max-height: 100%;
  min-height: 0;
}
.text__d846A {
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #acacac;
  text-align: center;
  min-width: 0;
  display: none;
}
.textnoMapsAndFolders__d846ArAguv {
  display: block;
}
.folderAndMapsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: auto;
  min-width: 0;
}
.folderAndMapsContainernoMapsAndFolders {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  min-height: 0;
}
.folderContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0.5em 1em;
}
.folderContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.folderContainer > :global(.__wab_flex-container) > *,
.folderContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.folderContainer > :global(.__wab_flex-container) > picture > img,
.folderContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
  margin-top: 6px;
}
.slotTargetFolders {
  font-size: 1em;
}
.largeItem__qJ4I2:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.svg__hGjZb {
  object-fit: cover;
  max-width: 100%;
  align-self: center;
  height: 1em;
}
.textInputFromMapMap__lntvG:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.svg__iKamJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tx4Rk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.mapContainer {
  display: flex;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
  padding: 0.5em 1em;
}
.mapContainer > :global(.__wab_flex-container) {
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  min-width: 0;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.mapContainer > :global(.__wab_flex-container) > *,
.mapContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.mapContainer > :global(.__wab_flex-container) > picture > img,
.mapContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
  margin-top: 6px;
}
.slotTargetMaps {
  font-size: 1em;
}
.slotTargetMaps > *,
.slotTargetMaps > :global(.__wab_slot) > *,
.slotTargetMaps > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetMaps
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetMaps > picture > img,
.slotTargetMaps > :global(.__wab_slot) > picture > img,
.slotTargetMaps > :global(.__wab_slot) > :global(.__wab_slot) > picture > img,
.slotTargetMaps
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
