.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  min-width: 9em;
  max-width: 15.75em;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .container {
    min-width: auto;
    width: auto;
    justify-self: flex-start;
    display: inline-flex;
  }
}
.containerisModal {
  min-width: 25em;
  max-width: 62.5em;
  overflow-x: visible;
  overflow-y: visible;
}
.quickAccessMenuHeader {
  display: block;
  position: relative;
  align-self: stretch;
  height: 1.65em;
  flex-shrink: 0;
}
.text {
  display: block;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.003em;
  line-height: 0.75em;
  color: #9ba2ae;
  position: absolute;
  top: 0.75em;
  left: 2.286em;
  font-size: 0.7em;
}
.glassFrame {
  background: url("./images/innerFrame.png") 0% 0% / 64px 86px repeat,
    rgba(255, 255, 255, 0.059);
  backdrop-filter: blur(3px);
  box-shadow: inset 0px 1px 3px 0px rgba(182, 183, 182, 0.059);
  width: 100%;
  display: flex;
  position: relative;
  overflow: auto;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  min-width: 0;
  -webkit-backdrop-filter: blur(3px);
  border-radius: 0.762em;
  padding: 0.6em;
}
.glassFrame > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 1.2px);
  height: calc(100% + 1.2px);
}
.glassFrame > :global(.__wab_flex-container) > *,
.glassFrame > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.glassFrame > :global(.__wab_flex-container) > picture > img,
.glassFrame
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 1.2px;
}
.glassFrameisModal {
  width: auto;
  overflow: visible;
  padding: 1em;
}
.topSection {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 0px 2px;
  border-bottom: 1px solid #5353535c;
}
.slotTargetQuickAccessItems {
  font-size: 0.6em;
}
.dropdownItem__buv9A:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___164Lp {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.dropdownItemGroup__pwpsf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dropdownItem__nkmme:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__lymjl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.dropdownItem__uwQkD:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__sscnj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.dropdownItem__bi7Vc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__w5By1 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.dropdownItem__ht6OP:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin: 0em;
}
.text__hSZtN {
  padding: 0em;
  margin: 0em;
}
.svg__jszxC {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.quickAccessModalShortcut {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.buttomButton {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  padding: 0.378em;
}
.buttomButton > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.buttomButton > :global(.__wab_flex-container) > *,
.buttomButton > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttomButton > :global(.__wab_flex-container) > picture > img,
.buttomButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.shortcutLabel:global(.__wab_instance) {
  position: relative;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 0.678em;
  height: 0.678em;
  max-width: 100%;
  transition-property: transform;
  transition-duration: 1s;
  cursor: pointer;
  flex-shrink: 0;
  -webkit-transition-property: transform;
  -webkit-transition-duration: 1s;
  padding: 0em;
}
.container .freeBox:hover {
  transform: scaleX(1.01) scaleY(1) scaleZ(1);
}
.svg {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-QjV5QaAKBvaD);
  width: 100%;
  height: 100%;
  display: block;
  transform: none;
  min-width: 0;
  min-height: 0;
}
