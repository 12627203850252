.root {
  width: auto;
  height: auto;
  display: inline-flex;
  flex-direction: row;
  cursor: pointer;
  transform: none;
  transition-property: transform;
  transition-duration: 0.2s;
  position: relative;
  -webkit-transition-property: transform;
  -webkit-transition-duration: 0.2s;
  border-width: 1px;
  border-style: none;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 3.6px);
  width: calc(100% + 3.6px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 3.6px;
}
.root:hover {
  transform: scaleX(1.05) scaleY(1.05) scaleZ(1);
}
.slotTargetIcon {
  color: #fff7ed;
}
.slotTargetIcon > *,
.slotTargetIcon > :global(.__wab_slot) > *,
.slotTargetIcon > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetIcon > picture > img,
.slotTargetIcon > :global(.__wab_slot) > picture > img,
.slotTargetIcon > :global(.__wab_slot) > :global(.__wab_slot) > picture > img,
.slotTargetIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  transition-property: all;
  transition-duration: 1s;
  overflow: hidden;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.root:hover .slotTargetIcon {
  color: var(--token-iMwcKa3CyOhF);
}
.svg__xcO51 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1.2em;
  height: 1.2em;
  display: block;
  flex-shrink: 0;
}
.labelUnderline4 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .labelUnderline4 {
    display: none;
  }
}
.slotTargetText2 {
  font-family: "Inter", sans-serif;
  font-size: 0.9em;
  font-weight: 400;
  color: rgba(155, 162, 174, 1);
  text-align: center;
  line-height: 1.2em;
  letter-spacing: 0em;
}
.slotTargetText2isActive {
  color: var(--token-y0YJXiYyD0g5);
}
.root:hover .slotTargetText2 {
  color: var(--token-_jBW4cEMf);
}
.underline4 {
  background: rgba(123, 57, 236, 1);
  height: 0.078em;
  display: block;
  position: relative;
  align-self: stretch;
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #8b5cf6;
  width: 0.9em;
  height: 0.9em;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
