@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B0%2C900&family=Nunito%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B0%2C900&family=Open+Sans%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800&family=JetBrains+Mono%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800&display=swap");

.plasmic_tokens {
  --token-_jBW4cEMf: var(--token-QjV5QaAKBvaD);
  --plasmic-token-eggshell: var(--token-_jBW4cEMf);
  --token-IlqwQAdMR: #d9d9d9;
  --plasmic-token-grey: var(--token-IlqwQAdMR);
  --token-cHh7GdL37vN: var(--antd-colorFillSecondary);
  --plasmic-token-system-fill-secondary: var(--token-cHh7GdL37vN);
  --token-R0iI9Z9H2K5U: #2a2828;
  --plasmic-token-dark-grey: var(--token-R0iI9Z9H2K5U);
  --token-t5atQMpMvMnt: #d6455b;
  --plasmic-token-red: var(--token-t5atQMpMvMnt);
  --token-BhM6H47WGk2d: #313131;
  --plasmic-token-darker-grey: var(--token-BhM6H47WGk2d);
  --token-Zl85VQ0F9czX: currentcolor;
  --plasmic-token-unnamed-style-token: var(--token-Zl85VQ0F9czX);
  --token-0TPNyb_-H-mv: var(--token-hUjhnouCnnUu);
  --plasmic-token-unnamed-style-token-2: var(--token-0TPNyb_-H-mv);
  --token-hUjhnouCnnUu: #f54646;
  --plasmic-token-unnamed-style-token-3: var(--token-hUjhnouCnnUu);
  --token-0_5ZnF2XNivQ: #ffffff;
  --plasmic-token-white: var(--token-0_5ZnF2XNivQ);
  --token-QjV5QaAKBvaD: rgba(255, 246, 236, 1);
  --plasmic-token-orange-50: var(--token-QjV5QaAKBvaD);
  --token-YkLB6UfGb1vT: rgba(155, 162, 174, 1);
  --plasmic-token-gray-400: var(--token-YkLB6UfGb1vT);
  --token-iMwcKa3CyOhF: #13a734;
  --plasmic-token-map-map-green: var(--token-iMwcKa3CyOhF);
  --token-sGsQKvIs65yI: #232323;
  --plasmic-token-unnamed-style-token-4: var(--token-sGsQKvIs65yI);
  --token-D6U7oE1K-Zwp: #bfc2c8;
  --plasmic-token-linear-light-grey: var(--token-D6U7oE1K-Zwp);
  --token-QJ5QDfn8ym9B: #98a2b3;
  --plasmic-token-linear-medium-grey: var(--token-QJ5QDfn8ym9B);
  --token-94mSMlGynTTS: #6369d2;
  --plasmic-token-linear-lavender: var(--token-94mSMlGynTTS);
  --token-pVazaMcHJbiw: #54b2ce;
  --plasmic-token-linear-teal: var(--token-pVazaMcHJbiw);
  --token-QCMhAo6RxCz1: #65b581;
  --plasmic-token-linear-mint-green: var(--token-QCMhAo6RxCz1);
  --token-k0xVm5waweZv: #ebc109;
  --plasmic-token-linear-yellow: var(--token-k0xVm5waweZv);
  --token-Lta0rC2n_gbn: #e79e4d;
  --plasmic-token-linear-orange: var(--token-Lta0rC2n_gbn);
  --token-ypaxj_piP02L: #f1cac2;
  --plasmic-token-linear-light-pink: var(--token-ypaxj_piP02L);
  --token-8_1-wQXNRiI2: #dd635a;
  --plasmic-token-linear-red: var(--token-8_1-wQXNRiI2);
  --token-yIwZ8EJwsuai: #5978e8;
  --plasmic-token-hub-hub-logo-blue: var(--token-yIwZ8EJwsuai);
  --token-fX-le9vmKMbn: #00000033;
  --plasmic-token-button-shadow: var(--token-fX-le9vmKMbn);
  --token-RvDOmLGk-LY_: #231f20;
  --plasmic-token-dark-background: var(--token-RvDOmLGk-LY_);
  --token-BXeiV-a2qwLW: #bebebe;
  --plasmic-token-medium-grey: var(--token-BXeiV-a2qwLW);
  --token-nOHen_OvbbpT: #5978e8;
  --plasmic-token-logo-blue: var(--token-nOHen_OvbbpT);
  --token-y0YJXiYyD0g5: #fdfdfc;
  --plasmic-token-almost-white: var(--token-y0YJXiYyD0g5);
  --token-6_-WiswQsnKE: #1dd35e;
  --plasmic-token-spotify-green: var(--token-6_-WiswQsnKE);
  --token-GNWWKDBEBUuk: #2280cc;
  --plasmic-token-twitter-blue: var(--token-GNWWKDBEBUuk);
  --token-9nqYSiPWdKIu: #353132;
  --plasmic-token-dark: var(--token-9nqYSiPWdKIu);
  --token-78Uefi8jQV8_: #7500bf;
  --plasmic-token-purple: var(--token-78Uefi8jQV8_);
  --token-lTQKd5MuxMjc: #2141b3;
  --plasmic-token-mountain-blue: var(--token-lTQKd5MuxMjc);
  --token-ZA9i_NBOQMH2: #eb8442;
  --plasmic-token-orange: var(--token-ZA9i_NBOQMH2);
  --token-iw-ATktI2XER: #f9c43c;
  --plasmic-token-yellow: var(--token-iw-ATktI2XER);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-HU7IyPy0oswX_font-family: "Inter", sans-serif;
  --mixin-HU7IyPy0oswX_font-size: 16px;
  --mixin-HU7IyPy0oswX_font-weight: 400;
  --mixin-HU7IyPy0oswX_font-style: normal;
  --mixin-HU7IyPy0oswX_color: #535353;
  --mixin-HU7IyPy0oswX_text-align: left;
  --mixin-HU7IyPy0oswX_text-transform: none;
  --mixin-HU7IyPy0oswX_line-height: 1.5;
  --mixin-HU7IyPy0oswX_letter-spacing: normal;
  --mixin-HU7IyPy0oswX_white-space: pre-wrap;
  --mixin-HU7IyPy0oswX_user-select: text;
  --mixin-HU7IyPy0oswX_text-decoration-line: none;
  --mixin-HU7IyPy0oswX_text-overflow: clip;
  --mixin-zqBDWnzNTTC8_color: #000000;
  --mixin-zqBDWnzNTTC8_font-weight: 900;
  --mixin-zqBDWnzNTTC8_font-size: 72px;
  --mixin-zqBDWnzNTTC8_line-height: 1;
  --mixin-zqBDWnzNTTC8_letter-spacing: -4px;
  --mixin-zqBDWnzNTTC8_white-space: pre-wrap;
  --mixin-BA2y_3Ju7dVG_color: #000000;
  --mixin-BA2y_3Ju7dVG_font-size: 48px;
  --mixin-BA2y_3Ju7dVG_font-weight: 700;
  --mixin-BA2y_3Ju7dVG_letter-spacing: -1px;
  --mixin-BA2y_3Ju7dVG_line-height: 1.1;
  --mixin-BA2y_3Ju7dVG_white-space: pre-wrap;
  --mixin-CD_69_NwsC6v_color: #0070f3;
  --mixin-CD_69_NwsC6v_white-space: pre-wrap;
  --mixin-HlLpRKmD0SXW_color: #000000;
  --mixin-HlLpRKmD0SXW_font-size: 32px;
  --mixin-HlLpRKmD0SXW_font-weight: 600;
  --mixin-HlLpRKmD0SXW_letter-spacing: -0.8px;
  --mixin-HlLpRKmD0SXW_line-height: 1.2;
  --mixin-HlLpRKmD0SXW_white-space: pre-wrap;
  --mixin-oGrtDnkuqHGh_color: #000000;
  --mixin-oGrtDnkuqHGh_font-size: 24px;
  --mixin-oGrtDnkuqHGh_font-weight: 600;
  --mixin-oGrtDnkuqHGh_letter-spacing: -0.5px;
  --mixin-oGrtDnkuqHGh_line-height: 1.3;
  --mixin-oGrtDnkuqHGh_white-space: pre-wrap;
  --mixin-wpwS3GY8Xy9C_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-wpwS3GY8Xy9C_border-bottom-color: #dddddd;
  --mixin-wpwS3GY8Xy9C_border-bottom-style: solid;
  --mixin-wpwS3GY8Xy9C_border-bottom-width: 1px;
  --mixin-wpwS3GY8Xy9C_border-left-color: #dddddd;
  --mixin-wpwS3GY8Xy9C_border-left-style: solid;
  --mixin-wpwS3GY8Xy9C_border-left-width: 1px;
  --mixin-wpwS3GY8Xy9C_border-right-color: #dddddd;
  --mixin-wpwS3GY8Xy9C_border-right-style: solid;
  --mixin-wpwS3GY8Xy9C_border-right-width: 1px;
  --mixin-wpwS3GY8Xy9C_border-top-color: #dddddd;
  --mixin-wpwS3GY8Xy9C_border-top-style: solid;
  --mixin-wpwS3GY8Xy9C_border-top-width: 1px;
  --mixin-wpwS3GY8Xy9C_border-bottom-left-radius: 3px;
  --mixin-wpwS3GY8Xy9C_border-bottom-right-radius: 3px;
  --mixin-wpwS3GY8Xy9C_border-top-left-radius: 3px;
  --mixin-wpwS3GY8Xy9C_border-top-right-radius: 3px;
  --mixin-wpwS3GY8Xy9C_font-family: "Inconsolata";
  --mixin-wpwS3GY8Xy9C_padding-bottom: 1px;
  --mixin-wpwS3GY8Xy9C_padding-left: 4px;
  --mixin-wpwS3GY8Xy9C_padding-right: 4px;
  --mixin-wpwS3GY8Xy9C_padding-top: 1px;
  --mixin-wpwS3GY8Xy9C_white-space: pre-wrap;
  --mixin-vU8tLomdME4__border-left-color: #dddddd;
  --mixin-vU8tLomdME4__border-left-style: solid;
  --mixin-vU8tLomdME4__border-left-width: 3px;
  --mixin-vU8tLomdME4__color: #888888;
  --mixin-vU8tLomdME4__padding-left: 10px;
  --mixin-vU8tLomdME4__white-space: pre-wrap;
  --mixin-ho4I4E6bvMUd_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-ho4I4E6bvMUd_border-bottom-color: #dddddd;
  --mixin-ho4I4E6bvMUd_border-bottom-style: solid;
  --mixin-ho4I4E6bvMUd_border-bottom-width: 1px;
  --mixin-ho4I4E6bvMUd_border-left-color: #dddddd;
  --mixin-ho4I4E6bvMUd_border-left-style: solid;
  --mixin-ho4I4E6bvMUd_border-left-width: 1px;
  --mixin-ho4I4E6bvMUd_border-right-color: #dddddd;
  --mixin-ho4I4E6bvMUd_border-right-style: solid;
  --mixin-ho4I4E6bvMUd_border-right-width: 1px;
  --mixin-ho4I4E6bvMUd_border-top-color: #dddddd;
  --mixin-ho4I4E6bvMUd_border-top-style: solid;
  --mixin-ho4I4E6bvMUd_border-top-width: 1px;
  --mixin-ho4I4E6bvMUd_border-bottom-left-radius: 3px;
  --mixin-ho4I4E6bvMUd_border-bottom-right-radius: 3px;
  --mixin-ho4I4E6bvMUd_border-top-left-radius: 3px;
  --mixin-ho4I4E6bvMUd_border-top-right-radius: 3px;
  --mixin-ho4I4E6bvMUd_font-family: "Inconsolata";
  --mixin-ho4I4E6bvMUd_padding-bottom: 3px;
  --mixin-ho4I4E6bvMUd_padding-left: 6px;
  --mixin-ho4I4E6bvMUd_padding-right: 6px;
  --mixin-ho4I4E6bvMUd_padding-top: 3px;
  --mixin-ho4I4E6bvMUd_white-space: pre-wrap;
  --mixin-pVOI-FYD0_XzA_display: flex;
  --mixin-pVOI-FYD0_XzA_flex-direction: column;
  --mixin-pVOI-FYD0_XzA_align-items: stretch;
  --mixin-pVOI-FYD0_XzA_justify-content: flex-start;
  --mixin-pVOI-FYD0_XzA_list-style-position: outside;
  --mixin-pVOI-FYD0_XzA_padding-left: 40px;
  --mixin-pVOI-FYD0_XzA_position: relative;
  --mixin-pVOI-FYD0_XzA_list-style-type: disc;
  --mixin-pVOI-FYD0_XzA_white-space: pre-wrap;
  --mixin-wKozRQwtsgJX-_display: flex;
  --mixin-wKozRQwtsgJX-_flex-direction: column;
  --mixin-wKozRQwtsgJX-_align-items: stretch;
  --mixin-wKozRQwtsgJX-_justify-content: flex-start;
  --mixin-wKozRQwtsgJX-_list-style-position: outside;
  --mixin-wKozRQwtsgJX-_padding-left: 40px;
  --mixin-wKozRQwtsgJX-_position: relative;
  --mixin-wKozRQwtsgJX-_list-style-type: decimal;
  --mixin-wKozRQwtsgJX-_white-space: pre-wrap;
  --mixin-_3eTyH2j-rSIK_color: #000000;
  --mixin-_3eTyH2j-rSIK_font-size: 20px;
  --mixin-_3eTyH2j-rSIK_font-weight: 600;
  --mixin-_3eTyH2j-rSIK_letter-spacing: -0.3px;
  --mixin-_3eTyH2j-rSIK_line-height: 1.5;
  --mixin-_3eTyH2j-rSIK_white-space: pre-wrap;
  --mixin-yXpRhj2Q9DYya_color: #000000;
  --mixin-yXpRhj2Q9DYya_font-size: 16px;
  --mixin-yXpRhj2Q9DYya_font-weight: 600;
  --mixin-yXpRhj2Q9DYya_line-height: 1.5;
  --mixin-yXpRhj2Q9DYya_white-space: pre-wrap;
  --mixin-VZtcoioSI-pvi_color: #3291ff;
  --mixin-VZtcoioSI-pvi_white-space: pre-wrap;
  --mixin-5yitHZNm1j9Es_white-space: pre-wrap;
  --mixin-3-CkTg5BtplH2_white-space: pre-wrap;
}

.plasmic_mixins {
  --mixin-gnKP5MHx6_white-space: pre-wrap;
  --plasmic-mixin-dark-linear_white-space: var(--mixin-gnKP5MHx6_white-space);
  --mixin-p8V7GoqcY_box-shadow: 0px 0px 0px 3px #96c7f2;
  --plasmic-mixin-light-blue-border-shadow_box-shadow: var(
    --mixin-p8V7GoqcY_box-shadow
  );
  --mixin-p8V7GoqcY_white-space: pre-wrap;
  --plasmic-mixin-light-blue-border-shadow_white-space: var(
    --mixin-p8V7GoqcY_white-space
  );
  --mixin-vPhrkaZCV_box-shadow: 0px 0px 0px 3px #f54646;
  --plasmic-mixin-red-border-shadow_box-shadow: var(
    --mixin-vPhrkaZCV_box-shadow
  );
  --mixin-vPhrkaZCV_white-space: pre-wrap;
  --plasmic-mixin-red-border-shadow_white-space: var(
    --mixin-vPhrkaZCV_white-space
  );
  --mixin-IKIG6KxuVzcj_white-space: pre-wrap;
  --plasmic-mixin-button-dark-grey-linear_white-space: var(
    --mixin-IKIG6KxuVzcj_white-space
  );
  --mixin-s_KxY7yXMjWb_white-space: pre-wrap;
  --plasmic-mixin-button-map-map-green-linear_white-space: var(
    --mixin-s_KxY7yXMjWb_white-space
  );
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-HU7IyPy0oswX_font-family);
  font-size: var(--mixin-HU7IyPy0oswX_font-size);
  font-weight: var(--mixin-HU7IyPy0oswX_font-weight);
  font-style: var(--mixin-HU7IyPy0oswX_font-style);
  color: var(--mixin-HU7IyPy0oswX_color);
  text-align: var(--mixin-HU7IyPy0oswX_text-align);
  text-transform: var(--mixin-HU7IyPy0oswX_text-transform);
  line-height: var(--mixin-HU7IyPy0oswX_line-height);
  letter-spacing: var(--mixin-HU7IyPy0oswX_letter-spacing);
  white-space: var(--mixin-HU7IyPy0oswX_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  color: var(--mixin-zqBDWnzNTTC8_color);
  font-weight: var(--mixin-zqBDWnzNTTC8_font-weight);
  font-size: var(--mixin-zqBDWnzNTTC8_font-size);
  line-height: var(--mixin-zqBDWnzNTTC8_line-height);
  letter-spacing: var(--mixin-zqBDWnzNTTC8_letter-spacing);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  color: var(--mixin-BA2y_3Ju7dVG_color);
  font-size: var(--mixin-BA2y_3Ju7dVG_font-size);
  font-weight: var(--mixin-BA2y_3Ju7dVG_font-weight);
  letter-spacing: var(--mixin-BA2y_3Ju7dVG_letter-spacing);
  line-height: var(--mixin-BA2y_3Ju7dVG_line-height);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-CD_69_NwsC6v_color);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  color: var(--mixin-HlLpRKmD0SXW_color);
  font-size: var(--mixin-HlLpRKmD0SXW_font-size);
  font-weight: var(--mixin-HlLpRKmD0SXW_font-weight);
  letter-spacing: var(--mixin-HlLpRKmD0SXW_letter-spacing);
  line-height: var(--mixin-HlLpRKmD0SXW_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  color: var(--mixin-oGrtDnkuqHGh_color);
  font-size: var(--mixin-oGrtDnkuqHGh_font-size);
  font-weight: var(--mixin-oGrtDnkuqHGh_font-weight);
  letter-spacing: var(--mixin-oGrtDnkuqHGh_letter-spacing);
  line-height: var(--mixin-oGrtDnkuqHGh_line-height);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-wpwS3GY8Xy9C_font-family);
  border-radius: var(--mixin-wpwS3GY8Xy9C_border-top-left-radius)
    var(--mixin-wpwS3GY8Xy9C_border-top-right-radius)
    var(--mixin-wpwS3GY8Xy9C_border-bottom-right-radius)
    var(--mixin-wpwS3GY8Xy9C_border-bottom-left-radius);
  padding: var(--mixin-wpwS3GY8Xy9C_padding-top)
    var(--mixin-wpwS3GY8Xy9C_padding-right)
    var(--mixin-wpwS3GY8Xy9C_padding-bottom)
    var(--mixin-wpwS3GY8Xy9C_padding-left);
  border-top: var(--mixin-wpwS3GY8Xy9C_border-top-width)
    var(--mixin-wpwS3GY8Xy9C_border-top-style)
    var(--mixin-wpwS3GY8Xy9C_border-top-color);
  border-right: var(--mixin-wpwS3GY8Xy9C_border-right-width)
    var(--mixin-wpwS3GY8Xy9C_border-right-style)
    var(--mixin-wpwS3GY8Xy9C_border-right-color);
  border-bottom: var(--mixin-wpwS3GY8Xy9C_border-bottom-width)
    var(--mixin-wpwS3GY8Xy9C_border-bottom-style)
    var(--mixin-wpwS3GY8Xy9C_border-bottom-color);
  border-left: var(--mixin-wpwS3GY8Xy9C_border-left-width)
    var(--mixin-wpwS3GY8Xy9C_border-left-style)
    var(--mixin-wpwS3GY8Xy9C_border-left-color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-vU8tLomdME4__color);
  padding-left: var(--mixin-vU8tLomdME4__padding-left);
  border-left: var(--mixin-vU8tLomdME4__border-left-width)
    var(--mixin-vU8tLomdME4__border-left-style)
    var(--mixin-vU8tLomdME4__border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-ho4I4E6bvMUd_font-family);
  border-radius: var(--mixin-ho4I4E6bvMUd_border-top-left-radius)
    var(--mixin-ho4I4E6bvMUd_border-top-right-radius)
    var(--mixin-ho4I4E6bvMUd_border-bottom-right-radius)
    var(--mixin-ho4I4E6bvMUd_border-bottom-left-radius);
  padding: var(--mixin-ho4I4E6bvMUd_padding-top)
    var(--mixin-ho4I4E6bvMUd_padding-right)
    var(--mixin-ho4I4E6bvMUd_padding-bottom)
    var(--mixin-ho4I4E6bvMUd_padding-left);
  border-top: var(--mixin-ho4I4E6bvMUd_border-top-width)
    var(--mixin-ho4I4E6bvMUd_border-top-style)
    var(--mixin-ho4I4E6bvMUd_border-top-color);
  border-right: var(--mixin-ho4I4E6bvMUd_border-right-width)
    var(--mixin-ho4I4E6bvMUd_border-right-style)
    var(--mixin-ho4I4E6bvMUd_border-right-color);
  border-bottom: var(--mixin-ho4I4E6bvMUd_border-bottom-width)
    var(--mixin-ho4I4E6bvMUd_border-bottom-style)
    var(--mixin-ho4I4E6bvMUd_border-bottom-color);
  border-left: var(--mixin-ho4I4E6bvMUd_border-left-width)
    var(--mixin-ho4I4E6bvMUd_border-left-style)
    var(--mixin-ho4I4E6bvMUd_border-left-color);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-pVOI-FYD0_XzA_display);
  flex-direction: var(--mixin-pVOI-FYD0_XzA_flex-direction);
  align-items: var(--mixin-pVOI-FYD0_XzA_align-items);
  justify-content: var(--mixin-pVOI-FYD0_XzA_justify-content);
  list-style-position: var(--mixin-pVOI-FYD0_XzA_list-style-position);
  padding-left: var(--mixin-pVOI-FYD0_XzA_padding-left);
  position: var(--mixin-pVOI-FYD0_XzA_position);
  list-style-type: var(--mixin-pVOI-FYD0_XzA_list-style-type);
  flex-column-gap: var(--mixin-pVOI-FYD0_XzA_flex-column-gap);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-wKozRQwtsgJX-_display);
  flex-direction: var(--mixin-wKozRQwtsgJX-_flex-direction);
  align-items: var(--mixin-wKozRQwtsgJX-_align-items);
  justify-content: var(--mixin-wKozRQwtsgJX-_justify-content);
  list-style-position: var(--mixin-wKozRQwtsgJX-_list-style-position);
  padding-left: var(--mixin-wKozRQwtsgJX-_padding-left);
  position: var(--mixin-wKozRQwtsgJX-_position);
  list-style-type: var(--mixin-wKozRQwtsgJX-_list-style-type);
  flex-column-gap: var(--mixin-wKozRQwtsgJX-_flex-column-gap);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  color: var(--mixin-_3eTyH2j-rSIK_color);
  font-size: var(--mixin-_3eTyH2j-rSIK_font-size);
  font-weight: var(--mixin-_3eTyH2j-rSIK_font-weight);
  letter-spacing: var(--mixin-_3eTyH2j-rSIK_letter-spacing);
  line-height: var(--mixin-_3eTyH2j-rSIK_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  color: var(--mixin-yXpRhj2Q9DYya_color);
  font-size: var(--mixin-yXpRhj2Q9DYya_font-size);
  font-weight: var(--mixin-yXpRhj2Q9DYya_font-weight);
  line-height: var(--mixin-yXpRhj2Q9DYya_line-height);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-VZtcoioSI-pvi_color);
}

:where(.root_reset) li:where(.li),
li:where(.root_reset.li),
:where(.root_reset .__wab_expr_html_text) li,
:where(.root_reset_tags) li,
li:where(.root_reset_tags) {
}

:where(.root_reset) p:where(.p),
p:where(.root_reset.p),
:where(.root_reset .__wab_expr_html_text) p,
:where(.root_reset_tags) p,
p:where(.root_reset_tags) {
}
