.root {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  justify-content: center;
  align-items: center;
  background: none;
  width: 100%;
  min-width: 0;
}
.insertItemPlaceholder {
  background: url("./images/searchFoldersButton.png") top 50% left 50% / 100% auto repeat,
    #0707074d;
  box-shadow: inset -2px -2px 2px 0px #1818181a, inset 0px 2px 2px 0px #1f1f1f1a;
  width: 100%;
  height: 32px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 6px;
  padding: 4px 7px;
  border: 1px dashed #fdfdfc1a;
}
.insertItemPlaceholder > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.insertItemPlaceholder > :global(.__wab_flex-container) > *,
.insertItemPlaceholder
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.insertItemPlaceholder > :global(.__wab_flex-container) > picture > img,
.insertItemPlaceholder
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.text {
  display: block;
  font-size: 10px;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
