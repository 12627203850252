.actionBarButton {
  width: 44px;
  height: 44px;
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: 6px;
  padding: 10px;
}
.actionBarButton > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.actionBarButton > :global(.__wab_flex-container) > *,
.actionBarButton > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.actionBarButton > :global(.__wab_flex-container) > picture > img,
.actionBarButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.actionBarButtonactiveState_ctrl {
  padding: 0px;
}
.actionBarButtonactiveState_cmd {
  padding: 0px;
}
.actionBarButtonactiveState_opt {
  padding: 0px;
}
.actionBarButtonactiveState_alt {
  padding: 0px;
}
.actionBarButtonactiveState_shi {
  padding: 0px;
}
.actionBarButtonactiveState_areaCreationMode {
  background: #ffffff1f;
}
.actionBarButtonactiveState_screenshotCreationMode {
  background: #ffffff1f;
}
.actionBarButton:hover {
  background: #ffffff1f;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}
.svg {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  position: relative;
  color: var(--token-y0YJXiYyD0g5);
  flex-shrink: 0;
  display: none;
}
.svgactiveState_ctrl {
  display: none;
}
.svgactiveState_cmd {
  display: none;
}
.svgactiveState_opt {
  display: none;
}
.svgactiveState_alt {
  display: none;
}
.svgactiveState_shi {
  display: none;
}
.svgactiveState_areaCreationMode {
  color: var(--token-y0YJXiYyD0g5);
  display: block;
}
.svgactiveState_screenshotCreationMode {
  color: var(--token-y0YJXiYyD0g5);
  display: block;
}
.svgisToggled {
  display: none;
}
.slotTargetChildren {
  color: #fff;
}
.slotTargetChildren > *,
.slotTargetChildren > :global(.__wab_slot) > *,
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetChildren > picture > img,
.slotTargetChildren > :global(.__wab_slot) > picture > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.actionBarButton:hover .slotTargetChildren {
  color: var(--token-iMwcKa3CyOhF);
}
.svg__i6Ix6 {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.activeKey {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: 34px;
  flex-direction: column;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  display: none;
  border-radius: 4px;
}
.activeKey > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.activeKey > :global(.__wab_flex-container) > *,
.activeKey > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.activeKey > :global(.__wab_flex-container) > picture > img,
.activeKey
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.activeKeyactiveState_ctrl {
  padding-bottom: 4px;
  width: 100%;
  min-width: 0;
  display: flex;
}
.activeKeyactiveState_ctrl > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
  min-width: 0;
}
.activeKeyactiveState_cmd {
  padding-bottom: 4px;
  width: 100%;
  min-width: 0;
  display: flex;
}
.activeKeyactiveState_cmd > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
  min-width: 0;
}
.activeKeyactiveState_opt {
  padding-bottom: 4px;
  width: 100%;
  min-width: 0;
  display: flex;
}
.activeKeyactiveState_opt > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
  min-width: 0;
}
.activeKeyactiveState_alt {
  padding-bottom: 4px;
  width: 100%;
  min-width: 0;
  display: flex;
}
.activeKeyactiveState_alt > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
  min-width: 0;
}
.activeKeyactiveState_shi {
  padding-bottom: 4px;
  width: 100%;
  min-width: 0;
  display: flex;
}
.activeKeyactiveState_shi > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
  min-width: 0;
}
.activeKeyisToggled {
  display: flex;
  border: 3px solid var(--token-iMwcKa3CyOhF);
}
.activeKeyactiveState_alt_isToggled {
  border-style: none;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "JetBrains Mono";
  font-weight: 800;
  min-width: 0;
}
.textactiveState_ctrl {
  font-weight: 700;
  font-size: 12px;
  text-align: center;
}
.textactiveState_cmd {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.textactiveState_opt {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.textactiveState_alt {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.textactiveState_shi {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.textactiveState_alt_isToggled {
  color: var(--token-iMwcKa3CyOhF);
}
