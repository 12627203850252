.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
}
.everythingContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 40em;
  background: #00000005;
  cursor: pointer;
  border-radius: 12.5px;
  padding: 1px;
  border: 0.4px solid #323233;
}
.everythingContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.everythingContainer > :global(.__wab_flex-container) > *,
.everythingContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.everythingContainer > :global(.__wab_flex-container) > picture > img,
.everythingContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.everythingContainercreationFlow_createFolderButton {
  border-style: none;
}
.everythingContainercreationFlow_createMapButton {
  border-style: none;
}
.titleCreationFrame {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 2em;
  min-width: 4em;
}
.titleCreationFramecreationFlow_titleInput {
  display: flex;
}
.textInputFromMapMap__m1JrZ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__oFmia {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yz2V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.iconAndEmojiSelectionFrame {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 2em;
  padding: 0px;
}
.emojiPickerContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.emojiPickerContainercreationFlow_iconOrEmojiSelection {
  width: auto;
}
.img__hYbnK {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  border-radius: 40px;
}
.img__hYbnK > picture > img {
  object-fit: cover;
}
.iconCreationFrame {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.iconCreationFramecreationFlow_emojiInputTemp {
  display: none;
}
.showMoreButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.showMoreButtoncreationFlow_iconOrEmojiSelection:global(.__wab_instance) {
  flex-shrink: 0;
  width: 100%;
}
.svg__rzqgp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text {
  color: #82ba8f;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 1em;
  color: #82ba8f;
}
.confirmButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___78Eur {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ns6Ol {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
