.logomark {
  width: 2.8em;
  height: 2.8em;
  display: block;
  cursor: pointer;
  position: relative;
}
.container {
  background: rgba(255, 255, 255, 0.05);
  width: 2.8em;
  height: 2.8em;
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.75em;
}
@media (min-width: 0px) and (max-width: 768px) {
  .container {
    background: #ffffff0d;
  }
}
.containercontext_map {
  background: none;
}
.logomark:hover .container {
  background: #ffffff0d;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  transform: translate(-0.19299999999999784px, -0.23100000000000165px);
  width: 1.4em;
  height: 1.4em;
  display: flex !important;
  top: auto;
  left: auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  width: 20px;
  height: 20px;
  color: var(--token-y0YJXiYyD0g5);
  flex-shrink: 0;
  display: none;
}
.svgcontext_map {
  display: block;
}
