.fancyButtonInnerContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 7.65em;
  height: 3.957em;
  justify-content: center;
  align-items: center;
  background: url("./images/fancyButtonBg.svg") top 50% left 50% / cover repeat;
  justify-self: flex-start;
  transition-property: transform;
  transition-duration: 0.2s;
  transform: none;
  -webkit-transition-property: transform;
  -webkit-transition-duration: 0.2s;
}
.fancyButtonInnerContainer:hover {
  transform: scaleX(1.02) scaleY(1.02) scaleZ(1);
}
.buttonContent {
  transform: translateX(0px) translateY(0px) translateZ(0px);
  width: 5.775em;
  height: 3.225em;
  display: block;
  position: absolute;
  top: 0.075em;
  left: 0.938em;
  cursor: pointer;
  flex-shrink: 0;
}
.text {
  transform: translate(-0.20800000000000018px, 0.17199999999999993px);
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 0.038em;
  left: -0.3em;
  flex-direction: row;
  padding: 0.44em 0.44em 0.527em;
}
.text > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 7.034px);
  width: calc(100% + 7.034px);
}
.text > :global(.__wab_flex-container) > *,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.text > :global(.__wab_flex-container) > picture > img,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 7.034px;
}
.texttype_randomMap {
  left: -11px;
}
.svg__u7XjC {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-QjV5QaAKBvaD);
  width: 0.616em;
  height: 0.616em;
  display: block;
  flex-shrink: 0;
}
.svgtype_randomMap__u7XjCdl5TS {
  display: none;
}
.svg__uxrcj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #fff7ed;
  width: 0.616em;
  height: 0.616em;
  transition-property: all;
  transition-duration: 1s;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.svgtype_randomMap__uxrcjDl5TS {
  display: block;
}
.text__lDgGy {
  display: block;
  font-size: 0.527em;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.003em;
  line-height: 0.7em;
  color: var(--token-QjV5QaAKBvaD);
  position: relative;
  margin-top: 0px;
}
.texttype_randomMap__lDgGydl5TS {
  white-space: pre;
}
.shortcutLabel {
  background: linear-gradient(
      232deg,
      rgba(65, 65, 65, 1) 49%,
      rgba(42, 37, 38, 1) 107%
    )
    0% 0% / 100% 100% no-repeat;
  box-shadow: inset 0px 0px 0px 0.468px rgba(64, 64, 64, 1);
  filter: drop-shadow(0px 1.172px 1.172px rgba(0, 0, 0, 0.34));
  transform: translate(-0.07000000000000028px, 0.10300000000000153px);
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 2.063em;
  left: 0.75em;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition-property: transform;
  transition-duration: 0.2s;
  -webkit-transition-property: transform;
  -webkit-transition-duration: 0.2s;
  border-radius: 0.263em;
  padding: 0.264em 0.527em;
}
.fancyButtonInnerContainer .shortcutLabel:hover {
  transform: scaleX(1.02) scaleY(1) scaleZ(1);
}
.text__v82L8 {
  display: block;
  font-size: 0.438em;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.003em;
  line-height: 0.62em;
  color: rgba(255, 255, 255, 1);
  position: relative;
  height: auto;
}
