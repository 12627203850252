.controls-area {
    position: absolute;
    visibility: hidden;
    top: -21px;
    width: 145px;
    border-radius: 10px 10px 0px 0px;
    background: url('./images/Hover\ Label\ Background.svg');
    background-size: cover;
    padding: 5px 20px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    overflow: hidden;
    transition: visibility 0.3s, opacity 0.3s ease, transform 0.3s ease;
}

.down-arrow {
    transform: rotate(90deg);
}

.hide-controls-area {
    visibility: hidden;
    opacity: 0;
    transform: translateY(100%);
}

.show-controls-area {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
}

.collapse-expand, .external-tab, .settings {
    cursor: pointer;
}

.title-area:hover {
    background: #2B2B2B;
    cursor: grab;
}

.preview-con:hover .preview-header .title-area {
    height: 45px;
    padding: 12px 12px 12px 30px;
    visibility: visible;
    opacity: 1;
}

.rotate {
    transform: rotate(-90deg);
    transition: transform 0.3s ease;
}
