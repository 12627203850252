.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.menuContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 2px;
}
.menuContainerisHidden {
  display: none;
}
.customComponent {
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.customComponentcontext_largeItem {
  display: none;
}
.customComponentcontext_customComponent {
  display: flex;
}
.customComponentisMetadataHidden {
  display: none;
}
.customComponentisMultipleLargeItemsSelected {
  display: none;
}
.menu {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  background: #353132b3;
  backdrop-filter: blur(4px);
  overflow: hidden;
  filter: blur(0px);
  box-shadow: 0px 2px 6px 2px var(--token-fX-le9vmKMbn);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 1em;
}
.menucontext_customComponent {
  display: none;
}
.showMoreButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__jl8S {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em;
  font-weight: 400;
  font-style: normal;
  color: var(--token-_jBW4cEMf);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.freeBox__diw0L {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  max-width: 1em;
  max-height: 1em;
  padding: 0px;
}
.icon3 {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
  height: 1em;
}
.expandedStack {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-RvDOmLGk-LY_);
  backdrop-filter: none;
  filter: blur(0px);
  min-width: 0;
  -webkit-backdrop-filter: none;
  padding: 0.5em 0.8em;
}
.expandedStackisInQuickAccess {
  display: none;
}
.expandedStackisInQuickAccess_context_largeItem {
  display: flex;
}
.freeBox__inMft {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 16em;
  flex-wrap: wrap;
  min-width: 0;
  padding: 0px;
}
.titleContainer {
  width: 100%;
  height: 100%;
  max-height: 3em;
  display: flex;
  flex-direction: row;
  min-width: 0;
  min-height: 0;
}
.slotTargetTitle {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em;
  font-weight: 400;
  font-style: normal;
  color: var(--token-_jBW4cEMf);
  text-align: left;
  text-transform: none;
  line-height: 1.6em;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
}
.slotTargetTitle > :global(.__wab_text),
.slotTargetTitle > :global(.__wab_expr_html_text),
.slotTargetTitle > :global(.__wab_slot-string-wrapper),
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_slot-string-wrapper),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: none;
  text-overflow: clip;
}
.author {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 0.6em;
  color: var(--token-BXeiV-a2qwLW);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
  display: none;
}
.date {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 0.4em;
  color: var(--token-BXeiV-a2qwLW);
  padding-top: 2px;
  padding-bottom: 2px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
  display: none;
}
.metadataContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.metadataContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.metadataContainer > :global(.__wab_flex-container) > *,
.metadataContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.metadataContainer > :global(.__wab_flex-container) > picture > img,
.metadataContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBox__mdQnu {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__mdQnu > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.freeBox__mdQnu > :global(.__wab_flex-container) > *,
.freeBox__mdQnu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mdQnu > :global(.__wab_flex-container) > picture > img,
.freeBox__mdQnu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 2px;
}
.freeBox__f5Kvp {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.text__t76Se {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 0.4em;
  color: var(--token-BXeiV-a2qwLW);
  padding-top: 2px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
.createdAtDateContainer {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetCreatedAtDate {
  font-family: "Open Sans", sans-serif;
  font-size: 0.5em;
  font-weight: 700;
  font-style: normal;
  color: var(--token-_jBW4cEMf);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
}
.slotTargetCreatedAtDate > :global(.__wab_text),
.slotTargetCreatedAtDate > :global(.__wab_expr_html_text),
.slotTargetCreatedAtDate > :global(.__wab_slot-string-wrapper),
.slotTargetCreatedAtDate > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetCreatedAtDate
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetCreatedAtDate
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetCreatedAtDate
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetCreatedAtDate
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetCreatedAtDate
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetCreatedAtDate
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetCreatedAtDate
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetCreatedAtDate
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox__j8YhJ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBoxcontext_largeItem__j8YhJyVBwl {
  display: none;
}
.menuOption__gvJe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.freeBox__bdt2E {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  max-width: 1em;
  max-height: 1em;
  padding: 0px;
}
.icon4 {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
  height: 1em;
}
.text__c7Y2 {
  font-family: "Open Sans", sans-serif;
  font-size: 0.7em;
  font-weight: 400;
  font-style: normal;
  color: var(--token-_jBW4cEMf);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.freeBox__bGUsx {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 3px;
}
.freeBox__bGUsx > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.freeBox__bGUsx > :global(.__wab_flex-container) > *,
.freeBox__bGUsx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__bGUsx > :global(.__wab_flex-container) > picture > img,
.freeBox__bGUsx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6px;
}
.openOriginalLinkButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.freeBox__oe4YW {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  max-width: 1em;
  max-height: 1em;
  padding: 0px;
}
.icon2 {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-nOHen_OvbbpT);
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  transform: scaleX(0.9) scaleY(0.9) scaleZ(1);
  min-width: 0;
  height: 1em;
}
.text__q0Isb {
  font-family: "Open Sans", sans-serif;
  font-size: 0.7em;
  font-weight: 400;
  font-style: normal;
  color: var(--token-_jBW4cEMf);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.menuOption__vF9Xn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__pbJvU {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  max-width: 1em;
  max-height: 1em;
  padding: 0px;
}
.icon11 {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
  min-width: 0;
  height: 1em;
}
.text__hHdaM {
  padding-left: 0px;
  font-family: "Open Sans", sans-serif;
  font-size: 0.7em;
  font-weight: 400;
  font-style: normal;
  color: var(--token-_jBW4cEMf);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.readAloudButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.readAloudButtonisInQuickAccess:global(.__wab_instance) {
  display: none;
}
.text__ukXun {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em;
  font-weight: 400;
  font-style: normal;
  color: var(--token-_jBW4cEMf);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.icon5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
  height: 1em;
}
.archiveButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___8BpZ {
  font-family: "Open Sans", sans-serif;
  font-size: 0.7em;
  font-weight: 400;
  font-style: normal;
  color: var(--token-_jBW4cEMf);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.icon9 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  transform: scaleX(0.9) scaleY(0.9) scaleZ(1);
  height: 1em;
}
.shareButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.shareButtonisInQuickAccess:global(.__wab_instance) {
  display: none;
}
.shareButtonisInQuickAccess_context_largeItem:global(.__wab_instance) {
  display: flex;
}
._delete {
  filter: none;
  backdrop-filter: none;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em;
  font-weight: 400;
  font-style: normal;
  color: var(--token-_jBW4cEMf);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  -webkit-backdrop-filter: none;
}
._deletecontext_largeItem {
  height: auto;
}
.icon {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
  height: 1em;
}
.addToQuickAccessButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.addToQuickAccessButtonisInQuickAccess:global(.__wab_instance) {
  display: flex;
}
.delete4 {
  filter: none;
  backdrop-filter: unset;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em;
  font-weight: 400;
  font-style: normal;
  color: var(--token-_jBW4cEMf);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  -webkit-backdrop-filter: unset;
}
.icon8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
  height: 1em;
}
.icon8isInQuickAccess {
  color: var(--token-k0xVm5waweZv);
}
.renameButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.delete2 {
  filter: none;
  backdrop-filter: none;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em;
  font-weight: 400;
  font-style: normal;
  color: var(--token-_jBW4cEMf);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  -webkit-backdrop-filter: none;
}
.icon6 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
  height: 1em;
}
.icon6context_largeItem {
  display: none;
}
.svg___3CwBu {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  width: 16px;
  height: 16px;
  display: block;
  overflow: hidden;
}
.changeIconButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.delete3 {
  filter: none;
  backdrop-filter: unset;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em;
  font-weight: 400;
  font-style: normal;
  color: var(--token-_jBW4cEMf);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  -webkit-backdrop-filter: unset;
}
.icon7 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
  height: 1em;
}
.icon7context_largeItem {
  display: none;
}
.svg__d5Du8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  width: 16px;
  height: 16px;
  display: block;
  overflow: hidden;
}
.svgcontext_largeItem__d5Du8YVBwl {
  display: none;
}
.svg__wMjb1 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  width: 16px;
  height: 16px;
  display: block;
  overflow: hidden;
}
.deleteButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.deleteButtonisMultipleLargeItemsSelected:global(.__wab_instance) {
  display: flex;
}
.delete5 {
  filter: none;
  backdrop-filter: unset;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em;
  font-weight: 400;
  font-style: normal;
  color: var(--token-_jBW4cEMf);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  height: auto;
  -webkit-backdrop-filter: unset;
}
.icon10 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
  height: 1em;
}
