.bar {
  background: rgba(253, 253, 253, 0.1);
  box-shadow: inset 0px 0px 0px 0.4px rgba(66, 66, 66, 1);
  backdrop-filter: blur(4px);
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.119));
  width: auto;
  height: auto;
  display: inline-flex;
  overflow: hidden;
  flex-direction: row;
  position: relative;
  -webkit-backdrop-filter: blur(4px);
  border-radius: 12px;
  padding: 8px 33px;
}
.bar > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.bar > :global(.__wab_flex-container) > *,
.bar > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.bar > :global(.__wab_flex-container) > picture > img,
.bar > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 20px;
}
.barbackgroundStyle_dark {
  background: #1e1e1e;
  box-shadow: inset 0px 0px 0px 0px #1f1f1f, 0px 2px 4px 0px #00000012;
}
.barbackgroundStyle_darkBrownish {
  background: var(--token-RvDOmLGk-LY_);
  box-shadow: inset 0px 0px 0px 0px #1f1f1f, 0px 2px 4px 0px #00000012;
}
.barcornerRadius__32 {
  border-radius: 32px;
}
