.map-preview{
    position: relative;
    cursor: pointer;
}

.map-preview .preview-overlay {
    display: none; 
}

.preview-overlay .overlay-text {
    display: none; 
}

.map-preview:hover .preview-overlay {
    display: block; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(30, 29, 29, 0.5);
    z-index: 50;
}

.map-preview:hover .overlay-text {
    display: block; 
    z-index: 51; 
    color: white;
    opacity: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center; 
    padding: 10px; 
    background: rgba(0, 0, 0, 0.5); 
}