.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 260px;
  height: auto;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  filter: drop-shadow(1px 2px 2px #0c0c0c26);
}
.commandSearch {
  background: rgba(38, 38, 38, 1);
  width: 100%;
  height: 33px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  padding-left: 10px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  min-width: 0;
  flex-shrink: 0;
  border-radius: 4px;
}
.commandSearch > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.commandSearch > :global(.__wab_flex-container) > *,
.commandSearch > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.commandSearch > :global(.__wab_flex-container) > picture > img,
.commandSearch
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.commandIcon {
  box-shadow: none;
  width: 17px;
  height: 17px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  left: 0px;
  top: 0px;
  color: #b2b2b2;
  height: 1em;
}
.divider {
  background: rgba(30, 30, 30, 1);
  width: 2px;
  height: 25px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 10px;
}
.text {
  font-size: 12px;
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(122, 122, 122, 1);
  position: relative;
  display: none;
}
.textInputFromMapMap__ktLKs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__dmbD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__j3Lg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.suggestionContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 4px 4px 0px 20px;
}
.suggestionContainer > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 3px);
  height: calc(100% + 3px);
}
.suggestionContainer > :global(.__wab_flex-container) > *,
.suggestionContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.suggestionContainer > :global(.__wab_flex-container) > picture > img,
.suggestionContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 3px;
}
.svg___8ZWSz {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.svg__qmW1E {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.svg__bbhyV {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
