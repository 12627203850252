.shortcut-input{
    background: rgb(187, 247, 208, 0.1);
    border: 1px none !important;
    height: 24px !important;
    font-size: 12px;
    padding: 0 3px;
}

.shortcut-input::placeholder{
    color: #BBF7D0 !important;
}

.style-title-input {
    background: none;
    border: none;
    height: 24px !important;
    font-size: 14px;
    padding-left: 0 !important;
}

.live-editor pre {
    background-color:  #000000 !important;
}

.ant-color-picker-trigger .ant-color-picker-color-block{
    width: 100% !important;
    height: '30px' !important;
    padding: 0 !important;
}

// .ant-color-picker-color-block-inner{
//     width: 40px !important;
// }

.chevron {
    transition: transform 0.2s;
  }
  
  .chevron.rotate-180 {
    transform: rotate(180deg);
  }