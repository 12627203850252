.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 12px;
}
.actionBar:global(.__wab_instance) {
  max-width: 100%;
}
.sequenceBar:global(.__wab_instance) {
  max-width: 100%;
}
.sequenceBarshowSequenceBar:global(.__wab_instance) {
  display: flex;
}
