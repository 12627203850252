.menuOption {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px 16px;
}
.menuOption > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.menuOption > :global(.__wab_flex-container) > *,
.menuOption > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.menuOption > :global(.__wab_flex-container) > picture > img,
.menuOption
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.menuOptionnoPadding {
  padding: 0px;
}
.menuOption:hover {
  background: var(--token-RvDOmLGk-LY_);
  overflow: hidden;
}
.slotTargetIcon {
  color: var(--token-_jBW4cEMf);
}
.freeBox__oUgI {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  max-width: 1em;
  max-height: 1em;
  flex-shrink: 0;
  padding: 0px;
}
.svg__he0MT {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-_jBW4cEMf);
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
  height: 1em;
}
.freeBox {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetTitle {
  font-size: 0.7em;
  color: var(--token-_jBW4cEMf);
}
.menuOption:hover .slotTargetTitle {
  color: var(--token-nOHen_OvbbpT);
}
.menuOptionsourceCategoryType_twitter:hover
  .slotTargetTitlesourceCategoryType_twitter {
  color: var(--token-GNWWKDBEBUuk);
}
.menuOptionsourceCategoryType_youtube:hover
  .slotTargetTitlesourceCategoryType_youtube {
  color: var(--token-t5atQMpMvMnt);
}
.menuOptionsourceCategoryType_spotify:hover
  .slotTargetTitlesourceCategoryType_spotify {
  color: var(--token-6_-WiswQsnKE);
}
