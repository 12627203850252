.root {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-self: flex-start;
  min-width: 0;
  padding: 0px;
}
.rootisSelectedOld {
  width: 100%;
  justify-self: flex-start;
  min-width: 140px;
  padding: 0px;
}
.rootisSelected {
  transform: scaleX(1.02) scaleY(1.02) scaleZ(1);
}
.freeBox__z1OpR {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: visible;
  min-width: 0;
  padding: 0px;
}
.freeBoxisSelectedOld__z1OpRp79T2 {
  border-radius: 6px;
  padding: 2px;
  margin: -2px;
}
.freeBoxisSelected__z1OpR3AQ4R {
  height: 31px;
  margin-top: 0px;
  margin-left: 0px;
  padding-top: 2px;
  padding-right: 2px;
  padding-left: 2px;
  overflow: visible;
  background: none;
  width: 103%;
  max-width: 103%;
  border-radius: 5px;
}
.glowWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.glowWrapperisSelectedOld {
  margin: 2px;
}
.glowWrapperisSelected {
  width: 100%;
  display: block;
  min-width: 0;
  padding: 0px;
}
.suggestedCommand {
  background: url("./images/innerFrame.png") 0% 0% / 80px 108px repeat,
    rgba(255, 255, 255, 0.119);
  box-shadow: inset 0px 0px 0px 0.6px rgba(255, 255, 255, 0.159);
  backdrop-filter: blur(3px);
  width: auto;
  height: 27px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex-grow: 1;
  min-width: 214px;
  -webkit-backdrop-filter: blur(3px);
  border-radius: 4px;
  padding: 7.696px;
}
.suggestedCommand > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.suggestedCommand > :global(.__wab_flex-container) > *,
.suggestedCommand > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.suggestedCommand > :global(.__wab_flex-container) > picture > img,
.suggestedCommand
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.suggestedCommandisSelectedOld {
  box-shadow: none;
  width: auto;
  min-width: 140px;
  padding-bottom: 7.696px;
  padding-left: 7.696px;
  border: 1.2px solid #ca24248f;
}
.suggestedCommandisSelected {
  background: url("./images/innerFrame.png") 0% 0% / 80px 108px repeat, #ffffff5e;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  box-shadow: 0px 0px 2px 1px #a01f1fad, inset 0px 0px 0px 0px #ffffff4a;
  min-width: 0;
  border: 1.2px solid #9d2121b8;
}
.suggestedCommandisSelected > :global(.__wab_flex-container) {
  min-width: 0;
}
.suggestedCommandisSelectedSimple {
  background: url("./images/innerFrame.png") 0% 0% / 80px 108px repeat, #7d2c2cad;
  box-shadow: inset 0px 0px 0px 0px rgba(255, 255, 255, 0.159),
    0px 0px 2px 0px var(--token-t5atQMpMvMnt);
  overflow: visible;
  filter: blur(0px);
  border: 1.2px solid #ff86868f;
}
.suggestedCommandisSuggestedMap {
  width: auto;
}
.suggestedCommandisSuggestedMap > :global(.__wab_flex-container) {
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.suggestedCommandisSuggestedMap > :global(.__wab_flex-container) > *,
.suggestedCommandisSuggestedMap
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.suggestedCommandisSuggestedMap
  > :global(.__wab_flex-container)
  > picture
  > img,
.suggestedCommandisSuggestedMap
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
  margin-top: 0px;
}
.root:hover .suggestedCommand {
  background: url("./images/innerFrame.png") 0% 0% / 80px 108px repeat, #8989891f;
}
.svg__c94Ke {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.slotTargetCommandNameSlot {
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  line-height: 100%;
  letter-spacing: 0em;
}
.slotTargetCommandNameSlotisSelectedOld {
  font-weight: 700;
  color: #740d07cc;
}
.slotTargetCommandNameSlotisSelected {
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #730c07cc;
  line-height: 100%;
  letter-spacing: 0em;
}
.slotTargetCommandNameSlotisSelectedSimple {
  color: #ffffffcc;
}
.slotTargetCommandSlot {
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: rgba(136, 136, 136, 1);
  line-height: 100%;
  letter-spacing: 0em;
}
.slotTargetCommandSlotisSelectedOld {
  color: var(--token-0_5ZnF2XNivQ);
}
.slotTargetCommandSlotisSelected {
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: var(--token-0_5ZnF2XNivQ);
  line-height: 100%;
  letter-spacing: 0em;
}
.slotTargetCommandSlotisSelectedSimple {
  color: var(--token-0_5ZnF2XNivQ);
}
.freeBox__cRa9 {
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBoxisSelected__cRa93AQ4R {
  display: none;
}
.freeBox__hdLnJ {
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: -4px;
  margin-right: 4px;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBoxisSelected__hdLnJ3AQ4R {
  position: absolute;
  left: 0px;
  top: -1px;
  height: 30px;
  filter: blur(14px);
  background: #9a2f2f7a;
  width: 105%;
  max-width: 105%;
  z-index: -5;
  display: flex;
  border-radius: 8px;
}
