.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.labelContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px;
}
.resultsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 10px;
  padding: 3px;
  border: 3px solid #ffffff;
}
.resultsContainer > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 3px);
  height: calc(100% + 3px);
}
.resultsContainer > :global(.__wab_flex-container) > *,
.resultsContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.resultsContainer > :global(.__wab_flex-container) > picture > img,
.resultsContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 3px;
}
.resultItem__qpDic:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.resultItem__kdLw3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
