.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  min-width: 0;
}
.header {
  width: 100%;
  height: 3.25em;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  min-width: 0;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.text__lcN6 {
  display: block;
  font-size: 1.75em;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 2.25em;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 0.5em;
  left: 13.3em;
  transform: translate(-0.29000000000002046px, -0.0009999999999976694px);
}
.topLeftButton:global(.__wab_instance) {
  position: absolute;
  top: 3px;
  left: 0px;
}
.mapTextSearchBar {
  background: #1e1e1e;
  box-shadow: inset -0.7px -1px 0px 0px rgba(50, 50, 50, 0.25),
    inset 0.699px 1px 1px 0px rgba(98, 98, 98, 0.25);
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
  transform: translate(-0.5px, -0.5px);
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 0.5em;
  left: auto;
  overflow: hidden;
  flex-direction: row;
  right: 7.5em;
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.2s;
  -webkit-transition-property: transform;
  -webkit-transition-duration: 0.2s;
  border-radius: 0.75em;
  padding: 0.25em 0.625em 0.25em 1.25em;
}
.mapTextSearchBar > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.mapTextSearchBar > :global(.__wab_flex-container) > *,
.mapTextSearchBar > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.mapTextSearchBar > :global(.__wab_flex-container) > picture > img,
.mapTextSearchBar
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .mapTextSearchBar {
    display: none;
  }
}
.root .mapTextSearchBar:hover {
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #fff7ed;
  width: 1em;
  height: 1em;
  display: block;
  flex-shrink: 0;
}
.text__iYRxa {
  display: block;
  font-size: 0.875em;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.77em;
  color: rgba(255, 255, 255, 0.699);
  position: relative;
  white-space: pre;
}
.shortcutLabel:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
}
.text__qiXmi {
  margin-right: 0px;
}
.userAvatars {
  transform: translate(-0.29700000000002547px, 0px);
  width: 2.25em;
  height: 2.25em;
  display: block;
  position: absolute;
  top: auto;
  left: auto;
  right: 0px;
  bottom: 10px;
}
.img__k2EkM {
  position: absolute;
  object-fit: fill;
  max-width: 100%;
  left: 0em;
  top: 0em;
  display: none !important;
}
.img__k2EkM > picture > img {
  object-fit: fill;
}
