.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.mapTextSearchWindow {
  background: rgba(0, 0, 0, 0.119);
  backdrop-filter: blur(12px);
  box-shadow: inset 1px 1px 1px 0px rgba(63, 63, 63, 0.25);
  width: 802px;
  height: 680px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  -webkit-backdrop-filter: blur(12px);
  border-radius: 23.399px;
  padding: 0px 3px 3px;
}
.behindFrame {
  width: 765px;
  height: 56px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  overflow: visible;
  margin-top: -31px;
  flex-shrink: 0;
}
.circleButtons {
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 44px;
  left: 727px;
  flex-direction: row;
  padding-top: 1px;
  padding-bottom: 1px;
  transform: none;
}
.circleButtons > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 14px);
  width: calc(100% + 14px);
}
.circleButtons > :global(.__wab_flex-container) > *,
.circleButtons > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.circleButtons > :global(.__wab_flex-container) > picture > img,
.circleButtons
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 14px;
}
.minimizeBtnContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.minimizeCircleButton:global(.__wab_instance) {
  flex-shrink: 0;
}
.closeBtnContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.closeCircleButton:global(.__wab_instance) {
  flex-shrink: 0;
}
.handAndFilterQucikAccess {
  width: 646px;
  height: auto;
  display: flex;
  position: absolute;
  top: 14px;
  left: 47px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.handle {
  border-bottom-left-radius: 20px;
  box-shadow: inset 0px -1px 2px 0px #0000004d, inset 0px -1px 2px 2px #0000001a;
  height: 18px;
  display: block;
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  border-right: 0.6px none #262626;
  border-bottom: 0.6px solid #262626;
  border-left: 0.6px solid #262626;
}
.svg__mYEqA {
  position: absolute;
  object-fit: cover;
  color: #fff;
  width: 454.154px;
  height: 16.802px;
  display: block;
  top: 1px;
  left: -1px;
  max-width: 100%;
}
.filterQuickAccess {
  background: rgba(0, 0, 0, 0.28);
  box-shadow: inset -2px -2px 2px 0px rgba(0, 0, 0, 0.18);
  border-top-left-radius: 14.4px;
  border-top-right-radius: 14.4px;
  border-bottom-right-radius: 14.4px;
  width: 255px;
  height: 35px;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  padding-right: 14px;
  flex-shrink: 0;
  border-right: 0.6px solid #262626;
  border-bottom: 0.6px solid #262626;
  border-left: 0.6px none #262626;
}
.filterQuickAccess > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.filterQuickAccess > :global(.__wab_flex-container) > *,
.filterQuickAccess > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.filterQuickAccess > :global(.__wab_flex-container) > picture > img,
.filterQuickAccess
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.resizeHandle {
  width: 2px;
  display: block;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-shrink: 0;
}
.root .resizeHandle:hover {
  background: #ffffff;
}
.root .resizeHandle:active:hover {
  box-shadow: 0px 0px 4px 0px #ffffff73;
}
.filterIcons {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 3px;
}
.filterIcons > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.filterIcons > :global(.__wab_flex-container) > *,
.filterIcons > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.filterIcons > :global(.__wab_flex-container) > picture > img,
.filterIcons
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.text__xtZjv {
  font-size: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  display: none;
}
.searchFrame {
  transform: translate(0px, -0.26200000000000045px);
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  flex-direction: row;
  margin-top: 0px;
  min-width: 0;
}
.searchFrame > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 446px);
  width: calc(100% + 446px);
}
.searchFrame > :global(.__wab_flex-container) > *,
.searchFrame > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.searchFrame > :global(.__wab_flex-container) > picture > img,
.searchFrame
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 446px;
}
.searchBox {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  min-width: 0;
  padding: 10px 10px 10px 42px;
}
.searchBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.searchBox > :global(.__wab_flex-container) > *,
.searchBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.searchBox > :global(.__wab_flex-container) > picture > img,
.searchBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.svg__gSBpx {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #fff7ed;
  width: 16px;
  height: 16px;
  display: block;
  flex-shrink: 0;
}
.textInputFromMapMap__hz3YM:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__fYyk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___94Sa3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.filters {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 32px;
}
.filters > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.filters > :global(.__wab_flex-container) > *,
.filters > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.filters > :global(.__wab_flex-container) > picture > img,
.filters
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.addFilter {
  background: rgba(0, 0, 0, 0.28);
  box-shadow: inset 0px 0px 0px 0.6px rgba(37, 37, 37, 1);
  width: auto;
  height: 36px;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 6px 14px 6px 12px;
}
.addFilter > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.addFilter > :global(.__wab_flex-container) > *,
.addFilter > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.addFilter > :global(.__wab_flex-container) > picture > img,
.addFilter
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.svg__sqsL1 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #fff7ed;
  width: 12px;
  height: 12px;
  display: block;
  flex-shrink: 0;
}
.text___3Zg8I {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0.005em;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.innerFrame {
  background: url("./images/innerFrame.png") 0% 0% / 64px 86px repeat, #000000b3;
  backdrop-filter: blur(30px);
  box-shadow: inset 1px 1px 0.5px 0px rgba(72, 71, 71, 0.2),
    inset 1px -1px 2px 0px rgba(0, 0, 0, 0.239);
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
  transform: translate(0px, -0.26200000000000045px);
  border-bottom-left-radius: 20.399px;
  border-bottom-right-radius: 20.399px;
  width: 796px;
  height: 100%;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  overflow: hidden;
  flex-direction: row;
  min-height: 0;
  -webkit-backdrop-filter: blur(30px);
  padding: 6px;
}
.innerFrame > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 0;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.innerFrame > :global(.__wab_flex-container) > *,
.innerFrame > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.innerFrame > :global(.__wab_flex-container) > picture > img,
.innerFrame
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.sidebar {
  width: 280px;
  height: 100%;
  display: flex;
  position: relative;
  overflow: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0;
  border-radius: 14.399px;
  padding: 6px;
}
.sidebar > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.sidebar > :global(.__wab_flex-container) > *,
.sidebar > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sidebar > :global(.__wab_flex-container) > picture > img,
.sidebar
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.resultFrame___3OdFf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.resultItem__p4FgJ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.resultItem__xnvIe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.resultFrame__uxWX:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.resultItem___3PWu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.resultItem__u1CiZ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.resultFrame___6Qa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.resultItem__fwLkF:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.resultItem__xIEdX:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.previewContainer {
  box-shadow: inset -2px -2px 1px 0px #00000042,
    inset 1px 1px 0px 0px rgba(1, 1, 1, 0.2);
  display: flex;
  position: relative;
  align-self: stretch;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 14.4px;
}
.freeBox__afi1K {
  background: url("./images/preview.png") -118px 1px / cover no-repeat;
  display: block;
  position: relative;
  align-self: stretch;
  flex-grow: 1;
  z-index: -3;
  border-radius: 14.4px;
}
