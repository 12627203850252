.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-self: flex-start;
  backdrop-filter: blur(20px);
  flex-grow: 1;
  min-width: 0;
  -webkit-backdrop-filter: blur(20px);
  border-radius: 0.75em;
  padding: 0.25em 0em 0.25em 0.5em;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 2px;
}
.rootlevel__0 {
  padding: 0.25em 0em 0.25em 0.5em;
}
.rootlevel__1 {
  background: #ffffff05;
}
.rootlevel__2 {
  background: #ffffff1f;
  padding: 0.25em 0em 0.25em 0.5em;
}
.rootlevel__3 {
  background: #ffffff33;
}
.rootisInSidebar {
  width: 100%;
  min-width: 0;
}
.rootisInSidebar > :global(.__wab_flex-container) {
  min-width: 0;
}
.rootisNotExpanded {
  backdrop-filter: unset;
  -webkit-backdrop-filter: unset;
}
.headContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
  min-width: 0;
}
.headContainerlevel__0 {
  padding: 0em;
}
.headContainerlevel__1 {
  padding: 0em;
}
.headContainerlevel__2 {
  padding: 0em;
}
.headContainerisInSidebar {
  padding: 0em;
}
.slotTargetHead {
  font-size: 1em;
}
.dropdownItem__f9KO3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___0ECZm {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.bodyContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
  min-width: 0;
  padding: 0em 0em 0em 0.7em;
}
.slotTargetBody {
  font-size: 1em;
}
.dropdownItem__v9YGa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__zEn1L {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.dropdownItem__lxKd4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__a83JM {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.dropdownItem__zbFzI:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin: 0em;
}
.text__fMGsR {
  padding: 0em;
  margin: 0em;
}
.svg__vwXr {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #a855f7;
  width: 100%;
  height: 100%;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  filter: drop-shadow(0px 2px 1px #222222);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
