.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.resultTextContainer {
  background: linear-gradient(#1a1a1a, #1a1a1a),
    linear-gradient(0deg, #2f2f31 0%, #3b3b3b 100%) 0% 0% / 100% 100% repeat;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: 6px 10px;
  border: 0.6px solid #6663631a;
}
.resultTextContainerisHighlighted {
  background: linear-gradient(0deg, #2f2f31 0%, #3b3b3b 100%) 0% 0% / 100% 100%
    repeat;
}
.slotTargetTextContent {
  font-size: 10px;
  color: var(--token-QjV5QaAKBvaD);
}
