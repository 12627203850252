/*  Accordion style  */
@keyframes accordion-down {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }
  
  @keyframes accordion-up {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }


  
  
  .accordion-down  {
    animation: accordion-down 0.2s ease-out;
  }
  
  .accordion-up {
    animation: accordion-up 0.2s ease-out;
  }
  

  /* Apply the rounded bottom class when the data-state attribute is "closed" */
[data-state="closed"] {
    border-bottom-left-radius: 1rem; /* Adjust the value as needed */
    border-bottom-right-radius: 1rem; /* Adjust the value as needed */
  }

  /*  For spinner in button component  */
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .spinner {
    animation: spin 1s linear infinite;
    border-radius: 50%;
    height: 1rem; /* h-4 */
    width: 1rem; /* w-4 */
    border-top: 2px solid white; /* border-t-2 border-white */
    border-bottom: 2px solid white; /* border-b-2 border-white */
  }