.shortcutLabel {
  background: linear-gradient(231deg, #3f3d3dfc 19%, #373334 108%) 0% 0% / 100%
    100% no-repeat;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
  width: auto;
  height: auto;
  display: inline-flex;
  overflow: hidden;
  flex-direction: row;
  position: relative;
  transition-property: transform;
  transition-duration: 0.1s;
  cursor: pointer;
  -webkit-transition-property: transform;
  -webkit-transition-duration: 0.1s;
  border-radius: 0.38em;
  padding: 0.38em 0.63em;
}
.shortcutLabel > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.shortcutLabel > :global(.__wab_flex-container) > *,
.shortcutLabel > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.shortcutLabel > :global(.__wab_flex-container) > picture > img,
.shortcutLabel
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.shortcutLabelwithBorder {
  border: 1px solid var(--token-R0iI9Z9H2K5U);
}
.shortcutLabel:hover {
  transform: scaleX(1.01) scaleY(1.01) scaleZ(1);
}
.slotTargetChildren {
  font-family: "Inter", sans-serif;
  font-size: 0.63em;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  line-height: 0.88em;
  letter-spacing: 0.005em;
  white-space: pre;
}
